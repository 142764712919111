import AdministrationHeader from "../administrationHeader";
import "../administration.css";
import NonpayedTable from "./nonpayedTable";

const NonpayedOverview = () => {
  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="nonpayed-content administration-content table">
        <h2>Nezaplacené přihlášky</h2>

        <NonpayedTable />
      </div>
    </div>
  );
};

export default NonpayedOverview;
