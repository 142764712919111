import { Button, Checkbox, Grid, TextField } from "@mui/material";
import "./subform.css";
import { ChildUnder15 } from "./formContainer";
import {
  nonNegativeIntegerLimited,
  selectOnFocus,
} from "../../helpers/NumericFieldHelper";

const CHILD_MIN_AGE = 0;
const CHILD_MAX_AGE = 15;
const CHILD_LIMIT_AGE = 4;

const ChildrenUnder15 = ({
  validateRequired,
  contribution,
  setContribution,
  form,
  setForm,
}: {
  validateRequired: (value: string) => boolean;
  contribution: number;
  setContribution: React.SetStateAction<any>;
  form: ChildUnder15[];
  setForm: React.SetStateAction<any>;
}) => {
  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let data: ChildUnder15[] = [...form];
    data[index] = {
      ...data[index],
      [event.target.name]: event.target.value,
    };
    setForm(data);
  };

  const handleBoolean = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let data: ChildUnder15[] = [...form];
    data[index] = {
      ...data[index],
      [event.target.name]: event.target.checked,
    };
    setForm(data);
  };

  const addNewChild = () => {
    setForm([
      ...form,
      {
        name: "",
        age: undefined,
        conference: false,
      },
    ]);
  };

  const removeChild = () => {
    if (form.length > 0) {
      let data: ChildUnder15[] = [...form];
      data.pop();
      setForm(data);
    }
  };

  const validateNumberInput = (
    event: any,
    index: number,
    min: number,
    max: number
  ) => {
    if (event.target.value === undefined || event.target.value === "") {
      let data: ChildUnder15[] = [...form];
      data[index] = {
        ...data[index],
        [event.target.name]: 0,
      };
      setForm(data);
    } else {
      let data: ChildUnder15[] = [...form];
      data[index] = {
        ...data[index],
        [event.target.name]: nonNegativeIntegerLimited(
          event.target.value,
          min,
          max
        ),
      };
      setForm(data);
    }

    if (nonNegativeIntegerLimited(
      event.target.value,
      min,
      max
    ) < CHILD_LIMIT_AGE) {
      let data: ChildUnder15[] = [...form];
      data[index] = {
        ...data[index],
        [event.target.name]: nonNegativeIntegerLimited(
          event.target.value,
          min,
          max
        ),
        conference: false,
      };
      setForm(data);
    }
  };

  return (
    <div className="subform">
      <h2 className="subform-header">Děti do 15 let</h2>
      <div className="childrenFormContainer">
        <Grid container spacing={2}>
          {form.length === 0 && (
            <Grid container item xs={12} spacing={3} mt={0.1} mb={1}>
              <Grid item xs={12}>
                <Button onClick={addNewChild}>Přidat dítě</Button>
              </Grid>
            </Grid>
          )}
          {form.length > 0 && (
            <>
              <Grid container item xs={12} spacing={3} mt={0.1} mb={1}>
                <Grid item xs={4}>
                  <span>Jméno *</span>
                </Grid>
                <Grid item xs={4}>
                  <span>Věk dítěte *</span>
                </Grid>
                <Grid item xs={4}>
                  <span>Zúčastní se Dětské konference</span>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                {form.map((child, index) => (
                  <Grid container item xs={12} spacing={3} key={index}>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        style={{ width: "100%" }}
                        name="name"
                        required
                        error={validateRequired(child.name)}
                        value={child.name}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(index, event)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        style={{ margin: "auto" }}
                        name="age"
                        type="number"
                        onBlur={(e) => {
                          validateNumberInput(
                            e,
                            index,
                            CHILD_MIN_AGE,
                            CHILD_MAX_AGE
                          );
                        }}
                        onFocus={selectOnFocus}
                        required
                        error={validateRequired(
                          child.age ? child.age.toString() : ""
                        )}
                        InputProps={{
                          inputProps: {
                            min: CHILD_MIN_AGE,
                            max: CHILD_MAX_AGE,
                          },
                        }}
                        value={child.age}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(index, event)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <div>
                        <Checkbox
                          name="conference"
                          checked={child.conference}
                          disabled={
                            !child.age ||
                            child.age < CHILD_LIMIT_AGE ||
                            child.age > CHILD_MAX_AGE
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleBoolean(index, event)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid container item xs={12} spacing={3} mt={0.1} mb={1}>
                <Grid item xs={6}>
                  <Button onClick={removeChild}>Odebrat</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={addNewChild}>Přidat dítě</Button>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3} mt={0.1} mb={1}>
                <Grid item xs={8}>
                  <span
                    style={{
                      textAlign: "left",
                      display: "block",
                      height: "100%",
                    }}
                  >
                    {" "}
                    Děkujeme též za Váš laskavý dobrovolný příspěvek na výdaje
                    Dětské konference{" "}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    id="outlined-number"
                    label={`Příspěvek za dítě`}
                    type="number"
                    onBlur={(e) => {
                      setContribution(
                        nonNegativeIntegerLimited(e.target.value, 0, 100000)
                      );
                    }}
                    name="contribution"
                    value={contribution}
                    onChange={(event) => setContribution(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default ChildrenUnder15;
