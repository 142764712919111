import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Unconfirmed } from "../../../types/ReportTypes";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";

const TABLE_ROWS = 10;

const UnconfirmedPaymentTable = () => {
  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState<Unconfirmed[] | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const [refetch, setRefetch] = useState(1);
  useEffect(() => {
    setIsPending(true);
    fetch("/api/Payment/unconfirmed", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        console.log(res);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setData(data.unconfirmed);
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [refetch]);

  const handleConfirm = (id: number) => {
    let fetchError = false;
    fetch(`/api/Payment/setConfirmed/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          fetchError = true;
          return res.json();
        }
        return res;
      })
      .then((r) => {
        if (fetchError) {
          throw Error(r.message);
        }
        setData((prevData) => {
          if (prevData === null) {
            return null;
          }
          return prevData.filter((row) => row.id !== id);
        });
      })
      .catch((err) => {
        setRefetch((prev) => prev + 1);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleCormfirmAll = () => {
    let fetchError = false;
    fetch("/api/Payment/confirmPayments", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          fetchError = true;
          return res.json();
        }
        return res;
      })
      .then((r) => {
        if (fetchError) {
          throw Error(r.message);
        }
        setData([]);
        enqueueSnackbar("Platební potvrzení byly odeslány na e-mail.", {
          variant: "success",
        });
      })
      .catch((err) => {
        setRefetch((prev) => prev + 1);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const columns: GridColDef[] = [
    {
      field: "confirm",
      headerName: "Potvrdit",
      width: 170,
      renderCell: (params) => {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();

              handleConfirm(params.row.id);
            }}
          >
            Označit jako potvrzené
          </a>
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "varSymbol",
      headerName: "Var. symbol",
      width: 90,
    },
    {
      field: "email",
      headerName: "Email",
      width: 100,
    },
    {
      field: "prijmeni",
      headerName: "Příjmení",
      width: 100,
    },
    {
      field: "jmeno",
      headerName: "Jméno",
      width: 100,
    },
    {
      field: "cenaCelkem",
      headerName: "Cena celkem",
      width: 120,
      type: "number",
    },
    {
      field: "castka",
      headerName: "Částka",
      width: 70,
      type: "number",
    },
    {
      field: "castkaCelkem",
      headerName: "Částka celkem",
      width: 120,
      type: "number",
      valueGetter: (value) => `${value || 0}`,
    },
    {
      field: "dobrovolnyPrispevek",
      headerName: "Dobrovolný přísp.",
      width: 130,
      type: "number",
    },
    {
      field: "dobrovolnyPrispevekDeti",
      headerName: "Dobrovolný přísp. děti",
      width: 150,
      type: "number",
    },
    {
      field: "datumZaplaceni",
      headerName: "Datum zaplacení",
      width: 150,
      type: "date",
      valueFormatter: (value) =>
        value ? new Date(value).toLocaleString() : "",
    },
    {
      field: "id",
      headerName: "Id platby",
      width: 70,
      type: "number",
    },
  ];

  return (
    <div className="table-container">
      {(isPending || data === null) && <div>Načítání...</div>}
      {!isPending && data !== null && (
        <>
          <Button onClick={handleCormfirmAll} variant="contained">
            Odeslat potvrzení těchto plateb na e-mail
          </Button>
          <DataGrid
            rows={data}
            columns={columns}
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: TABLE_ROWS,
                },
              },
            }}
            pageSizeOptions={[TABLE_ROWS]}
          />
        </>
      )}
    </div>
  );
};

export default UnconfirmedPaymentTable;
