import AdministrationHeader from "./administrationHeader";

const Unauthorized = () => {
    return ( 
        <div className="administration">
        <AdministrationHeader />
        <div className="accomodation-content administration-content table">
            <h2>Nemáte právo zobrazit tuto stránku</h2>
        </div>
        </div>
     );
}
 
export default Unauthorized;