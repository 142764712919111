import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { Roles } from "../../../../helpers/RolesEnum";

const ChangeRoleModal = (props: any) => {
  const [role, setRole] = useState(Roles.Admin.toString());

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };

  const handleClose = (success: boolean, data: any = null) => {
    props.close(success, data);
  };

  const onSuccess = (event: React.FormEvent<HTMLFormElement>) => {
    handleClose(true, role);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose(false)}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSuccess(event);
        },
      }}
    >
      <DialogTitle>Změnit roli</DialogTitle>
      <Select
        style={{ width: "300px", margin: "0 1.5rem" }}
        labelId="role"
        id="role"
        value={role.toString()}
        onChange={handleChange}
      >
        <MenuItem value={Roles.Admin.toString()}>Administrátor</MenuItem>
        <MenuItem value={Roles.BasicUser.toString()}>Uživatel</MenuItem>
      </Select>

      <DialogActions>
        <Button
          onClick={() => {
            handleClose(false);
          }}
          variant="contained"
        >
          Zrušit
        </Button>
        <Button type="submit" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeRoleModal;
