import Content from "../../common/content";
import AdministrationHeader from "../administrationHeader";
import AddPaymentForm from "./addPaymentForm";

const AddPaymentPage = () => {

  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="payment-content administration-content">
        <Content>
          <h2>Zadání nové platby</h2>
          <AddPaymentForm />
        </Content>
      </div>
    </div>
  );
};

export default AddPaymentPage;
