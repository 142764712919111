import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegistrationPage from "./components/registration/registrationPage";
import RegistrationEndedPage from "./components/registration/registrationEndedPage";
import "./App.css";
import AdminLandingPage from "./components/administration/adminLandingPage";
import AccomodationOverview from "./components/administration/accomodation/accomodationOverview";
import RegistrationOverview from "./components/administration/registration/registrationOverview";
import ImportBankStatement from "./components/administration/importBankStatement/importBankStatement";
import { SnackbarProvider } from "notistack";
import Login from "./components/administration/login/login";
import PrivateRoute from "./privateRoute";
import { Roles } from "./helpers/RolesEnum";
import Unauthorized from "./components/administration/unauthorized";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { csCZ } from "@mui/x-data-grid/locales";
import UnconfirmedPaymentOverview from "./components/administration/unconfirmedPayment/unconfirmedPaymentOverview";
import UsersContainer from "./components/administration/users/usersContainer";
import RegistrationEdit from "./components/administration/registration/registrationEdit";
import ChildOverview from "./components/administration/children/childOverview";
import PresentationOverview from "./components/administration/presentation/presentationOverview";
import AddPaymentPage from "./components/administration/addPayment/addPaymentPage";
import NonpayedOverview from "./components/administration/nonpayed/nonpayedOverview";
import ReportOverview from "./components/administration/report/reportOverview";
import AdminRegistrationPage from "./components/registration/adminRegistrationPage";
import AdminRegistrationWithoutVSPage from "./components/registration/adminRegistrationWithoutVSPage";
import FoodOverview from "./components/administration/food/foodOverview";
import FormOnSite from "./components/administration/registrationOnSite/formOnSite";
import Notification from "./components/administration/notification/notification";

const theme = createTheme({}, csCZ);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={1}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/" Component={RegistrationPage} />
              <Route path="/ukonceno" Component={RegistrationEndedPage} />
              <Route path="/login" Component={Login} />
              <Route path="/unauthorized" Component={Unauthorized} />
              <Route
                path="/admin"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <AdminLandingPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/ubytovani"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <AccomodationOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/pridatplatbu"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <AddPaymentPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/deti"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <ChildOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/prednasky"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <PresentationOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/nezaplaceneprihlasky"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <NonpayedOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/prihlasky"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <RegistrationOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/registrace"
                element={
                  <PrivateRoute guard={[Roles.Admin]}>
                    <AdminRegistrationPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/registracebezvs"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <AdminRegistrationWithoutVSPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/prihlasky/:id"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <RegistrationEdit />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/importplateb"
                element={
                  <PrivateRoute guard={[Roles.Admin]}>
                    <ImportBankStatement />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/nepotvrzeneplatby"
                element={
                  <PrivateRoute guard={[Roles.Admin]}>
                    <UnconfirmedPaymentOverview />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/users"
                element={
                  <PrivateRoute guard={[Roles.Admin]}>
                    <UsersContainer />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/reports"
                element={
                  <PrivateRoute guard={[Roles.Admin]}>
                    <ReportOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/stravovani"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <FoodOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/stravovani"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <FoodOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/prodej"
                element={
                  <PrivateRoute guard={[Roles.Admin, Roles.BasicUser]}>
                    <FormOnSite />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/notifikace"
                element={
                  <PrivateRoute guard={[Roles.Admin]}>
                    <Notification />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
