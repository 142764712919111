import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "notistack";

const AddUserModal = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();

  const [fieldError, setFieldError] = useState<boolean>(false);

  const handleClose = (success: boolean, data: any = null) => {
    props.close(success === true, data);
  };

  const validatePasswordFormat = (arg: string) => {
    const passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(arg);
  };

  const onSuccess = (event: React.FormEvent<HTMLFormElement>) => {
    if (fieldError) {
      return;
    }

    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());

    if (!validatePasswordFormat(formJson.password)) {
      enqueueSnackbar(
        "Heslo musí obsahovat minimálně 8 znaků, malá a velká písmena a čislovky. Příklad: 'Test12345'",
        { variant: "error" }
      );
      return;
    }

    handleClose(true, formJson);
  };

  const validate = (event: any) => {
    setFieldError(
      event.target.value === null || event.target.value.trim().length === 0
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSuccess(event);
        },
      }}
    >
      <DialogTitle>Přidat uživatele</DialogTitle>
      <DialogContent className="dialog-content-fields-container">
        <TextField
          autoFocus
          error={fieldError}
          required
          id="username"
          name="username"
          label="uživatelské jméno"
          type="text"
          fullWidth
          variant="standard"
          onChange={validate}
        />
        <TextField
          error={fieldError}
          required
          id="name"
          name="name"
          label="Jméno"
          type="text"
          fullWidth
          variant="standard"
          onChange={validate}
        />
        <TextField
          error={fieldError}
          required
          id="surname"
          name="surname"
          label="Přijmení"
          type="text"
          fullWidth
          variant="standard"
          onChange={validate}
        />
        <TextField
          error={fieldError}
          required
          id="password"
          name="password"
          label="Heslo"
          type="text"
          fullWidth
          variant="standard"
          onChange={validate}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(false);
          }}
          variant="contained"
        >
          Zrušit
        </Button>
        <Button type="submit" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
