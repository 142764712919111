import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Administration from "../../../types/AdministrationTypes";
import { Roles } from "../../../helpers/RolesEnum";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import { useCallback } from "react";

interface UsersTableProps {
    users: Administration.User[];
    deleteAction: (id: number) => void;
    editPasswordAction: (id: number) => void;
    changeRoleAction: (id: number) => void;
    mutation: (userId: number, data: any) => void;
}

const UsersTable = ({ users, deleteAction, editPasswordAction, changeRoleAction, mutation }: UsersTableProps) => {

    const idString = localStorage.getItem("userID");
    const currentUserID = idString !== null ? parseInt(idString) : 0;

    const columns: GridColDef[] = [
        { field: "username", headerName: "Uživatelské jméno", editable: true, width: 250 },
        { field: "name", headerName: "Jméno", editable: true, width: 250 },
        { field: "surname", headerName: "Příjmení", editable: true, width: 250 },
        {
            field: "role",
            headerName: "Role",
            editable: true,
            width: 100,
            type: 'singleSelect',
            valueOptions: ['Admin', 'Uživatel'],
        },
        {
            field: 'changeRoleAction',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            width: 50,
            renderCell: (params) => (
                params.row.id !== currentUserID ? (
                    <IconButton
                        aria-label="change role"
                        onClick={() => { changeRoleAction(parseInt(params.row.id)) }}
                    >
                        <PersonIcon />
                    </IconButton>
                ) : null
            ),
        },
        {
            field: 'editAction',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            width: 50,
            renderCell: (params) => (
                <IconButton
                    aria-label="edit"
                    onClick={() => { editPasswordAction(parseInt(params.row.id)) }}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
        {
            field: 'deleteAction',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            width: 50,
            renderCell: (params) => (
                params.row.id !== currentUserID ? (
                    <IconButton
                        aria-label="delete"
                        onClick={() => { deleteAction(parseInt(params.row.id)) }}
                    >
                        <DeleteIcon />
                    </IconButton>
                ) : null
            ),
        }
    ];

    const rows = users.map((x) => (
        {
            id: x.id,
            username: x.username,
            name: x.name,
            surname: x.surname,
            role: x.role === Roles.Admin ? 'Admin' : 'Uživatel'
        }
    ));

    const hasChanged = (oldRow: any, newRow: any) => {

        if (oldRow === null || newRow === null) {
            return false;
        }

        return (oldRow.name !== newRow.name)
            || (oldRow.username !== newRow.username)
            || (oldRow.surname !== newRow.surname)
            || (oldRow.role !== newRow.role);
    }


    const processRowUpdate = useCallback(
        (newRow: any, oldRow: any) => {

            if (hasChanged(oldRow, newRow)) {
                mutation(parseInt(newRow.id), newRow);
            }

            return newRow;
        },
        [],
    );

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        console.log("error on update row");
    }, []);

    return (
        <DataGrid
            style={{ minHeight: "200px"}}
            rows={rows}
            columns={columns}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            }}
            pageSizeOptions={[10, 20, 40]}
        />
    );
}

export default UsersTable;
