import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const NoteBoard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);
  const [noteSaved, setNoteSaved] = useState("");
  const [note, setNote] = useState("");
  const [id, setId] = useState("");
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setFetching(true);
    let fetchError = false;
    fetch("/api/Note/get", {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("userToken")}` },
    })
      .then((response) => {
        if (!response.ok) {
          fetchError = true;
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (fetchError) {
          setError(true);
          enqueueSnackbar(data.message, { variant: "error" });
        } else {
          setNoteSaved(data.note.zprava);
          setNote(data.note.zprava);
          setId(data.note.id);
        }
        setFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveNote = () => {
    const data = {
      id: id,
      zprava: note,
    };
    setFetching(true);
    let fetchError = false;
    fetch("/api/Note/update", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          fetchError = true;
        }
        return response.json();
      })
      .then((data) => {
        if (fetchError) {
          setNote(noteSaved);
          console.log(data);
          enqueueSnackbar("Ehh", { variant: "error" });
        } else {
          setNoteSaved(note);
          setEditMode(false);
          enqueueSnackbar("Nástěnka byla úspěšně uložena", {
            variant: "success",
          });
        }
        setFetching(false);
      });
  };

  return (
    <div>
      {fetching ? (
        <div>Načítám...</div>
      ) : (
        <div>
          {error && <div>Chyba při načítání nástěnky</div>}
          {!error && (
            <>
              <div className="noteboard-text">{parse(note)}</div>
              {editMode && (
                <ReactQuill theme="snow" value={note} onChange={setNote} />
              )}
              <div className="noteboard-buttons">
                <Button
                  variant="contained"
                  onClick={() => {
                    if (editMode) {
                      setNote(noteSaved);
                    }
                    setEditMode(!editMode);
                  }}
                >
                  {editMode ? "Zrušit" : "Upravit"}
                </Button>
                {editMode && (
                  <Button variant="contained" onClick={handleSaveNote}>
                    Uložit
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NoteBoard;
