import { useEffect, useState } from "react";
import {
  DataGrid, GridColDef, GridToolbarContainerProps,
  GridToolbarContainer, GridToolbarExportContainer,
  GridCsvExportMenuItem, GridPrintExportMenuItem
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import GridExcelExportMenuItem from "../../common/gridExcelExportMenuItem";

const ROW_HEIGHT = 32;
const columns: GridColDef[] = [
  { field: "pritomen", headerName: "Přítomen", type: "boolean", width: 90 },
  { field: "zaplaceno", headerName: "Zaplaceno", type: "boolean", width: 90 },
  { field: "prihlasek", headerName: "Přihlášek", type: "number", width: 90 },
  { field: "snidaneCt", headerName: "Snídaně Čt", type: "number", width: 90 },
  { field: "obedCt", headerName: "Oběd Čt", type: "number", width: 80 },
  { field: "obedBagetaCt", headerName: "Oběd bageta Čt", type: "number", width: 120 },
  { field: "vecereTeplaCt", headerName: "Večeře teplá Čt", type: "number", width: 120 },
  { field: "vecereBagetaCt", headerName: "Večeře bageta Čt", type: "number", width: 130 },
  { field: "snidanePa", headerName: "Snídaně Pá", type: "number", width: 90 },
  { field: "obedPa", headerName: "Oběd Pá", type: "number", width: 80 },
  { field: "obedBagetaPa", headerName: "Oběd bageta Pá", type: "number", width: 120 },
  { field: "vecereTeplaPa", headerName: "Večeře teplá Pá", type: "number", width: 120 },
  { field: "vecereBagetaPa", headerName: "Večeře bageta Pá", type: "number", width: 130 },
  { field: "snidaneSo", headerName: "Snídaně So", type: "number", width: 90 },
  { field: "obedSo", headerName: "Oběd So", type: "number", width: 80 },
  { field: "obedBagetaSo", headerName: "Oběd bageta So", type: "number", width: 120 },
  { field: "vecereTeplaSo", headerName: "Večeře teplá So", type: "number", width: 120 },
  { field: "vecereBagetaSo", headerName: "Večeře bageta So", type: "number", width: 130 },
  { field: "snidaneNe", headerName: "Snídaně Ne", type: "number", width: 90 },
  { field: "obedNe", headerName: "Oběd Ne", type: "number", width: 80 },
  { field: "obedBagetaNe", headerName: "Oběd bageta Ne", type: "number", width: 120 },
  { field: "celkemStravovani", headerName: "Celkem stravování Kč", type: "number", width: 160 },
];

const columnsSum: GridColDef[] = [
  { field: "prihlasek", headerName: "Přihlášek", type: "number", width: 90 },
  { field: "snidaneCt", headerName: "Snídaně Čt", type: "number", width: 90 },
  { field: "obedCt", headerName: "Oběd Čt", type: "number", width: 80 },
  { field: "obedBagetaCt", headerName: "Oběd bageta Čt", type: "number", width: 120 },
  { field: "vecereTeplaCt", headerName: "Večeře teplá Čt", type: "number", width: 120 },
  { field: "vecereBagetaCt", headerName: "Večeře bageta Čt", type: "number", width: 130 },
  { field: "snidanePa", headerName: "Snídaně Pá", type: "number", width: 90 },
  { field: "obedPa", headerName: "Oběd Pá", type: "number", width: 80 },
  { field: "obedBagetaPa", headerName: "Oběd bageta Pá", type: "number", width: 120 },
  { field: "vecereTeplaPa", headerName: "Večeře teplá Pá", type: "number", width: 120 },
  { field: "vecereBagetaPa", headerName: "Večeře bageta Pá", type: "number", width: 130 },
  { field: "snidaneSo", headerName: "Snídaně So", type: "number", width: 90 },
  { field: "obedSo", headerName: "Oběd So", type: "number", width: 80 },
  { field: "obedBagetaSo", headerName: "Oběd bageta So", type: "number", width: 120 },
  { field: "vecereTeplaSo", headerName: "Večeře teplá So", type: "number", width: 120 },
  { field: "vecereBagetaSo", headerName: "Večeře bageta So", type: "number", width: 130 },
  { field: "snidaneNe", headerName: "Snídaně Ne", type: "number", width: 90 },
  { field: "obedNe", headerName: "Oběd Ne", type: "number", width: 80 },
  { field: "obedBagetaNe", headerName: "Oběd bageta Ne", type: "number", width: 120 },
  { field: "celkemStravovani", headerName: "Celkem stravování Kč", type: "number", width: 160 },
];

const FoodTable = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [dataSum, setDataSum] = useState<any>(null);

  useEffect(() => {
    setIsPending(true);
    fetch("/api/Report/getFood", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data) => {
        setData(data.foods);
        let d = data.foods.reduce((a: any, b: any) => ({
          prihlasek: a.prihlasek + b.prihlasek,
          snidaneCt: a.snidaneCt + b.snidaneCt,
          obedCt: a.obedCt + b.obedCt,
          obedBagetaCt: a.obedBagetaCt + b.obedBagetaCt,
          vecereTeplaCt: a.vecereTeplaCt + b.vecereTeplaCt,
          vecereBagetaCt: a.vecereBagetaCt + b.vecereBagetaCt,
          snidanePa: a.snidanePa + b.snidanePa,
          obedPa: a.obedPa + b.obedPa,
          obedBagetaPa: a.obedBagetaPa + b.obedBagetaPa,
          vecereTeplaPa: a.vecereTeplaPa + b.vecereTeplaPa,
          vecereBagetaPa: a.vecereBagetaPa + b.vecereBagetaPa,
          snidaneSo: a.snidaneSo + b.snidaneSo,
          obedSo: a.obedSo + b.obedSo,
          obedBagetaSo: a.obedBagetaSo + b.obedBagetaSo,
          vecereTeplaSo: a.vecereTeplaSo + b.vecereTeplaSo,
          vecereBagetaSo: a.vecereBagetaSo + b.vecereBagetaSo,
          snidaneNe: a.snidaneNe + b.snidaneNe,
          obedNe: a.obedNe + b.obedNe,
          obedBagetaNe: a.obedBagetaNe + b.obedBagetaNe,
          celkemStravovani: a.celkemStravovani + b.celkemStravovani,
        }));
        setDataSum([d]);
        setIsPending(false);
      })
      .catch((err) => {
        setError(true);
        setIsPending(false);
        enqueueSnackbar("Chyba při načítání dat", { variant: "error" });
        console.log(err.message);
      });
  }, []);

  const csvOptions = {
    fileName: 'stravovani',
    delimiter: ';',
    utf8WithBom: true,
  };

  function CustomToolbar(props: GridToolbarContainerProps) {
    return (<GridToolbarContainer {...props}>
      <GridToolbarExportContainer>
        <GridExcelExportMenuItem columns={columns} fileName="stravovani" />
        <GridCsvExportMenuItem options={csvOptions} />
        <GridPrintExportMenuItem />
      </GridToolbarExportContainer>
    </GridToolbarContainer>);
  }

  return (
    <div className="table-container">
      {(isPending || data === null) && <div>Načítání...</div>}
      {error && <div>Chyba při načítání dat</div>}
      {!isPending && data !== null && !error && (
        <>
          <DataGrid
            getRowId={(row: any) => row.pritomen + ':' + row.zaplaceno}
            rows={data}
            columns={columns}
            rowHeight={ROW_HEIGHT}
            slots={{ toolbar: CustomToolbar }}
            pageSizeOptions={[100]}
          />
          <b style={{ alignSelf: 'flex-start' }}>Suma</b>
          <DataGrid style={{ alignSelf: 'flex-start' }}
            getRowId={(row: any) => row.pritomen + ':' + row.zaplaceno}
            rows={dataSum}
            columns={columnsSum}
            rowHeight={ROW_HEIGHT}
            slots={{ toolbar: CustomToolbar }}
            hideFooter={true}
          />
        </>
      )}
    </div>
  );
};

export default FoodTable;
