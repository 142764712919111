import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";

const ROW_HEIGHT = 32;
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "nazev",
    headerName: "Název",
    width: 170,
  },
  {
    field: "cenaNoc",
    headerName: "Cena za noc",
    width: 120,
    valueGetter: (value) => `${value || "0"} Kč`,
  },
  {
    field: "kapacita",
    headerName: "Kapacita",
    type: "number",
    width: 120,
  },
  {
    field: "zaplnenoSt",
    headerName: "Středa/Čtvrtek",
    type: "number",
    width: 120,
  },
  {
    field: "zaplnenoCt",
    headerName: "Čtvrtek/Pátek",
    type: "number",
    width: 120,
  },
  {
    field: "zaplnenoPa",
    headerName: "Pátek/Sobota",
    type: "number",
    width: 120,
  },
  {
    field: "zaplnenoSo",
    headerName: "Sobota/Neděle",
    type: "number",
    width: 120,
  },
];

const AccomodationTable = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    setIsPending(true);
    fetch("/api/Accomodation/getAll", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        console.log(res);
        return res.json();
      })
      .then((data) => {
        console.log(data.accomodations);
        setData(data.accomodations);
        setIsPending(false);
      })
      .catch((err) => {
        setError(true);
        setIsPending(false);
        enqueueSnackbar("Chyba při načítání dat", { variant: "error" });
        console.log(err.message);
      });
  }, []);

  return (
    <div className="table-container">
      {(isPending || data === null) && <div>Načítání...</div>}
      {error && <div>Chyba při načítání dat</div>}
      {!isPending && data !== null && !error && (
        <DataGrid
          rows={data}
          columns={columns}
          rowHeight={ROW_HEIGHT}
          pageSizeOptions={[100]}
        />
      )}
    </div>
  );
};

export default AccomodationTable;
