import { TextField } from "@mui/material";
import "./customVariableSymbol.css";
import { nonNegativeIntegerLimited } from "../../helpers/NumericFieldHelper";

interface CustomVariableSymbolProps{
    varSymbol: number;
    error: boolean;
    setVarSymbol: React.SetStateAction<any>;
}
const CustomVariableSymbol = ({error, varSymbol, setVarSymbol}: CustomVariableSymbolProps) => {

    return ( 
        <div className="subform">
            <h2 className="subform-header">Variabilní symbol</h2>
            <div className="customsymbolcontainer">
                <TextField
                    size="small"
                    type="number"
                    name="varSymbol"
                    label="Variabilní symbol"
                    value={varSymbol}
                    error={error}
                    onBlur={(e) => {setVarSymbol(nonNegativeIntegerLimited(e.target.value, 90000, 1000000))}}
                    onChange={(e) => setVarSymbol(e.target.value)}
                />
            </div>
        </div>
     );
}
 
export default CustomVariableSymbol;