import AdministrationHeader from "../administrationHeader";
import "../administration.css";
import ReactQuill from "react-quill";
import { useState } from "react";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const [isPending, setIsPending] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const goHome = () => {
    navigate("/admin");
  }

  const send = () => {
    let tmp = document.createElement('div');
    tmp.innerHTML = message;
    if (subject.trim().length === 0 || tmp.innerText.trim().length === 0) {
      enqueueSnackbar("Zpráva neobsahuje předmět nebo zprávu", { variant: "error" });
      return;
    }

    if (window.confirm("Opravdu chcete odeslat tuto zprávu na všechny účastníky?") == false) {
      return
    }

    setIsPending(true);

    const data = { subject: subject, message: message };

    fetch("/api/Notification/send", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          console.log(res);
          if (res.status === 400) {
            throw Error("Chyba při odesílání zprávy");
          } else {
            throw Error("Could not fetch the data for that resource!");
          }
        }

        return res.json();
      })
      .then((data) => {
        console.log(data);
        enqueueSnackbar("Zpráva odeslána", { variant: "success" });
        setIsPending(false);
        goHome();
      })
      .catch((err) => {
        setIsPending(false);
        enqueueSnackbar("Chyba při odesílání zprávy", { variant: "error" });

        console.log(err);
        console.log(err.message);
      });
  };

  return (
    <>
      {isPending && <div className="working">Pracuji ...</div>}
      <div className="administration">
        <AdministrationHeader />
        <div className="administration-content table">
          <h2>E-mailová notifikace</h2>
          <div>
            <label>Předmět:</label>
            <TextField
              size="small"
              style={{ width: "100%" }}
              name="name"
              required
              value={subject}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>
              ) => setSubject(event.target.value)}
            />
          </div>
          <div style={{ paddingTop: '20px' }}>
            <label>Zpráva:</label>
            <ReactQuill theme="snow" value={message} onChange={setMessage} />
          </div>
          <div>
            <Button variant="contained" style={{ marginTop: "20px" }} onClick={send}>
              Odeslat
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
