import { Grid, TextField } from "@mui/material";
import "./subform.css";
import "./conferencePrice.css";
import { Prices } from "./formContainer";
import { useEffect } from "react";
import {
  nonNegativeIntegerLimited,
  selectOnFocus,
} from "../../helpers/NumericFieldHelper";

const MINTIKETS = 0;
const MAXTIKETS = 20;

const ConferencePrice = ({
  sum,
  setSum,
  prices,
  form,
  setForm,
}: {
  sum: number;
  setSum: React.SetStateAction<any>;
  prices: Prices;
  form: any;
  setForm: React.SetStateAction<any>;
}) => {
  const calculateSum = () => {
    setSum(
      form.multiday * prices.wholeMultiday +
        form.multidayReduced * prices.reducedMultiday +
        form.thursday * prices.wholeDay +
        form.thursdayReduced * prices.reducedDay +
        form.friday * prices.wholeDay +
        form.fridayReduced * prices.reducedDay +
        form.saturday * prices.wholeDay +
        form.saturdayReduced * prices.reducedDay +
        form.sunday * prices.wholeDay +
        form.sundayReduced * prices.reducedDay
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    calculateSum();
  }, [form]);

  const validateNumberInput = (e: any, min: number, max: number) => {
    if (e.target.value === undefined || e.target.value === "") {
      setForm({
        ...form,
        [e.target.name]: 0,
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: nonNegativeIntegerLimited(e.target.value, min, max),
      });
    }
  };

  return (
    <div className="subform">
      <h2 className="subform-header">Konferenční poplatek</h2>
      <p className="subform-note">
        Nárok na snížený vstup mají: důchodci, ZTP, mládež 15-18 a studenti
      </p>
      <div className="conferencePriceFormContainer">
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <span>Počet osob</span>
            </Grid>
            <Grid item xs={4}>
              <span>Počet osob, které platí PLNÝ poplatek</span>
            </Grid>
            <Grid item xs={4}>
              <span>Počet osob, které platí SNÍŽENÝ poplatek</span>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4} mt={0.5}>
              <span> Vícedenní vstup </span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.wholeMultiday}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="multiday"
                value={form.multiday}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.reducedMultiday}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="multidayReduced"
                value={form.multidayReduced}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <span>Jednodenní vstup</span>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4} mt={0.5}>
              <span> Čtvrtek </span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.wholeDay}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="thursday"
                value={form.thursday}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.reducedDay}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="thursdayReduced"
                value={form.thursdayReduced}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4} mt={0.5}>
              <span> Pátek </span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.wholeDay}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="friday"
                value={form.friday}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.reducedDay}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="fridayReduced"
                value={form.fridayReduced}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4} mt={0.5}>
              <span> Sobota </span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.wholeDay}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="saturday"
                value={form.saturday}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.reducedDay}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="saturdayReduced"
                value={form.saturdayReduced}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4} mt={0.5}>
              <span> Neděle </span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.wholeDay}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="sunday"
                value={form.sunday}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`x ${prices.reducedDay}Kč`}
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MINTIKETS, MAXTIKETS);
                }}
                onFocus={selectOnFocus}
                name="sundayReduced"
                value={form.sundayReduced}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={8} mt={0.5}>
              <span style={{ textAlign: "right" }}>
                {" "}
                Celkem za konferenční poplatky{" "}
              </span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`Celkem Kč`}
                type="number"
                name="sum"
                value={sum}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ConferencePrice;
