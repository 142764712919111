import { useSnackbar } from "notistack";
import Label from "./label";
import { useEffect, useState } from "react";
import { LabelData } from "../../../types/RegistrationTypes";

const LabelsContainer = ({
  variableSymbols,
  qrcode,
}: {
  variableSymbols: Number[];
  qrcode: any;
}) => {
  const [isPending, setIsPending] = useState(false);
  const [labels, setLabels] = useState<LabelData[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsPending(true);
    let fetchError = false;
    fetch("/api/Registration/getLabelsData", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(variableSymbols),
    })
      .then((res) => {
        if (!res.ok) {
          fetchError = true;
        }
        return res.json();
      })
      .then((data) => {
        if (fetchError) {
          enqueueSnackbar(data.message, { variant: "error" });
          setIsPending(false);
          return;
        }
        console.log(data);
        setLabels(data.labels);
        setIsPending(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        setIsPending(false);
      });
  }, []);

  return (
    <div className="conference-label-container">
      {isPending && (
        <div
          style={{
            margin: "auto",
            marginTop: "1rem",
            width: "100%",
            textAlign: "center",
          }}
        >
          Načítám...
        </div>
      )}
      {labels.map((label: any, index: number) => (
        <Label key={index} label={label} qrcode={qrcode} />
      ))}
    </div>
  );
};

export default LabelsContainer;
