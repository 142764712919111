import "./registrationSended.css";
import "./subform.css";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";

const RegistrationSended = ({
  id,
  exchangeRate,
}: {
  id: number;
  exchangeRate: number;
}) => {
  const [isPending, setIsPending] = useState(false);
  const [qrcode, setQrcode] = useState<string>("");
  const [registrationData, setRegistrationData] = useState<any>(null);
  const [paymentData, setPaymentData] = useState<string>("");

  useEffect(() => {
    setIsPending(true);
    fetch("/api/Registration/order?id=" + id)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setQrcode(data.qrCodeData);
        setRegistrationData(data.registration);
        setPaymentData(data.payment);
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="subform">
      <h2 className="subform-header">Přihláška odeslána</h2>
      <p className="subform-note">
        Děkujeme za Vaši přihlášku. Pokud jste zadali správnou e-mailovou
        adresu, měli byste být v brzké době kontaktováni.
      </p>
      {isPending && <div>Načítání...</div>}
      {!isPending && (
        <div className="registrationSendedContainer">
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <h3>Platební údaje</h3>
              </Grid>
              {registrationData?.zeme === 0 && (
                <Grid item xs={6}>
                  <h3>QR platba</h3>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} style={{ textAlign: "left", fontSize: "13px" }}>
                <pre>Variabilní symbol: {registrationData?.varSymbol}</pre>
                <pre>{paymentData}</pre>
                <pre>
                  Částka:{" "}
                  {registrationData?.zeme === 0
                    ? registrationData?.cenaCelkem
                    : (registrationData?.cenaCelkem / exchangeRate).toFixed(2)}
                  ,- {registrationData?.zeme === 0 ? "Kč" : "EUR"}
                </pre>
              </Grid>
              {registrationData?.zeme === 0 && (
                <Grid item xs={6}>
                  <QRCode
                    size={200}
                    style={{ height: "auto", maxWidth: "250px" }}
                    level="M"
                    value={qrcode}
                    viewBox={`0 0 200 200`}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default RegistrationSended;
