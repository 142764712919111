import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { PresentationReport } from "../../../types/ReportTypes";

const ROW_HEIGHT = 32;
const columns: GridColDef[] = [
  {
    field: "nazev",
    headerName: "Název",
    width: 350,
  },
  {
    field: "prednasejici",
    headerName: "Přednášející",
    width: 300,
  },
  {
    field: "pocetPrihlasenych",
    headerName: "Počet přihlášených",
    type: "number",
    width: 150,
  },
];

const PresentationTable = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<PresentationReport[]>([]);

  useEffect(() => {
    setIsPending(true);
    fetch("/api/Presentation/getReport", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data: PresentationReport[]) => {
        setData(data);
        setIsPending(false);
        console.log(data);
      })
      .catch((err) => {
        setError(true);
        setIsPending(false);
        enqueueSnackbar("Chyba při načítání dat", { variant: "error" });
        console.log(err.message);
      });
  }, []);

  return (
    <div className="table-container">
      {(isPending || data === null) && <div>Načítání...</div>}
      {error && <div>Chyba při načítání dat</div>}
      {!isPending && data !== null && !error && (
        <DataGrid
          rows={data}
          columns={columns}
          rowHeight={ROW_HEIGHT}
          pageSizeOptions={[100]}
        />
      )}
    </div>
  );
};

export default PresentationTable;
