import { Grid, TextField } from "@mui/material";
import "./subform.css";
import {
  nonNegativeIntegerLimited,
  selectOnFocus,
} from "../../helpers/NumericFieldHelper";

const MAX_PRESENTATION = 100;
const Presentation = ({
  presentations,
  form,
  setForm,
}: {
  presentations: any;
  form: number[];
  setForm: React.SetStateAction<any>;
}) => {
  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let data: number[] = [...form];
    data[index] = parseInt(event.target.value);

    setForm(data);
  };

  const validateNumberInput = (
    event: any,
    index: number,
    min: number,
    max: number
  ) => {
    if (event.target.value === undefined || event.target.value === "") {
      let data: number[] = [...form];
      data[index] = 0;

      setForm(data);
    } else {
      let data: number[] = [...form];
      data[index] = nonNegativeIntegerLimited(event.target.value, min, max);

      setForm(data);
    }
  };

  return (
    <div className="subform">
      <h2 className="subform-header">Vyberte přednášku</h2>
      <p className="subform-note">
        Sdělte nám prosím, v jakém počtu navštívíte který z pátečních
        paralelních programů (viz{" "}
        <a href="https://konferencecharis.cz/#program" target="_blank">Program konference</a> -
        19.30-21.00 Paralelní večerní programy na různých místech v Brně),
        abychom je mohli lépe připravit.
      </p>
      <div className="presentationFormContainer">
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <span>Přednáška</span>
            </Grid>
            <Grid item xs={4}>
              <span>Přednášející</span>
            </Grid>
            <Grid item xs={4}>
              <span>Počet účastníků</span>
            </Grid>
          </Grid>
          {presentations.map((presentation: any, index: number) => (
            <Grid container item xs={12} spacing={3} key={index}>
              <Grid item xs={4} mt={0.5}>
                <span>{presentation.nazev}</span>
              </Grid>
              <Grid item xs={4} mt={0.5}>
                <span>{presentation.prednasejici}</span>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  style={{ width: "100%" }}
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, index, 0, MAX_PRESENTATION);
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="count"
                  value={form[index]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(index, event)
                  }
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Presentation;
