import FoodTable from "./foodTable";
import AdministrationHeader from "../administrationHeader";
import "../administration.css";

const AccomodationOverview = () => {
  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="administration-content table">
        <h2>Report stravování</h2>

        <FoodTable />
      </div>
    </div>
  );
};

export default AccomodationOverview;
