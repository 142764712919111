import { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import displayPDFInNewWindowWithAuthorization from "./registrationPDFLoader";
import { Button, IconButton, MenuItem, Pagination, PaginationItem, TextField, Tooltip } from "@mui/material";
import "./registrationTable.css";
import openLabelPage from "../label/labelOpen";
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';

const TABLE_ROWS = 15;
const ROW_HEIGHT = 32;

const DEFAULT_PRESENT = 0;
const DEFAULT_PAYED = 0;
const DEFAULT_DELETED = 1;

const options = [
  { value: 0, label: "Vše" },
  { value: 1, label: "Ne" },
  { value: 2, label: "Ano" },
];

const RegistrationTable = () => {
  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState<any>(null);
  const [filteredData, setFilteredData] = useState<any>(null);

  const [filt, setFilt] = useState<any>([]);
  const [quickFilt, setQuickFilt] = useState<any>([""]);
  const [present, setPresent] = useState<number>(DEFAULT_PRESENT);
  const [payed, setPayed] = useState<number>(DEFAULT_PAYED);
  const [deleted, setDeleted] = useState<number>(DEFAULT_DELETED);

  const gridRef = useGridApiRef();

  const { enqueueSnackbar } = useSnackbar();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: TABLE_ROWS,
    page: 0,
  });

  const navigate = useNavigate();

  const [sendButtonActive, setSendButtonActive] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]); //<Set<any>>(

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        sx={{ color: "#ffffff" }}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  const handleDelete = (registrationId: number) => {
    let isError = false;
    fetch(
      "/api/Registration/delete?id=" + registrationId ??
      "0" + "&userId=" + localStorage.getItem("userID") ??
      "0",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          isError = true;
          return res.json();
        }
        return res.json();
      })
      .then((d) => {
        getAll();
      })
      .then((d) => {
        if (!isError) {
          enqueueSnackbar('Záznam byl označen jako "smazaný"', { variant: 'success' });
        } else {
          enqueueSnackbar("Chyba smazání nebo záznam je již smazán", { variant: 'error' });
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
      });
  };

  const getAll = () => {
    fetch("/api/Registration/getAll", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        console.log(res);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setData(data.registrations);
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    setIsPending(true);
    getAll();
  }, []);

  useEffect(() => {
    setFilteredData(filterData(data));
    console.log(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [present, payed, deleted, data]);

  const columns: GridColDef[] = [
    {
      field: "Upravit",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        const linkUrl = `prihlasky/${params.row.id}`;
        return <a href={linkUrl}>Upravit</a>;
      },
      getApplyQuickFilterFn: undefined,
      sortable: false,
      filterable: false,
      disableExport: true
    },
    {
      field: "Pdf",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        const linkUrl = `/api/registration/download/${params.row.varSymbol}`;
        const headers = new Headers();
        headers.append(
          "Authorization",
          `Bearer ${localStorage.getItem("userToken")}`
        );

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.open(linkUrl + '?guid=' + btoa(localStorage.getItem("userToken") as string), '_blank');
              //displayPDFInNewWindowWithAuthorization(linkUrl, headers).catch(
              //  () => {
              //    enqueueSnackbar("Nepodařilo se načíst soubor.", {
              //      variant: "error",
              //    });
              //  }
              //);
            }}
          >
            PDF
          </a>
        );
      },
      getApplyQuickFilterFn: undefined,
      sortable: false,
      filterable: false,
      disableExport: true
    },
    //{ field: "id", headerName: "ID", width: 40 },
    {
      field: "varSymbol",
      headerName: "Var. symbol",
      width: 90,
    },
    {
      field: "prijmeni",
      headerName: "Příjmení",
      width: 100,
    },
    {
      field: "jmeno",
      headerName: "Jméno",
      width: 100,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "telefon",
      headerName: "Telefon",
      width: 100,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "email",
      headerName: "Email",
      width: 100,
    },
    {
      field: "datumObjednani",
      headerName: "Datum objednání",
      width: 120,
      type: "date",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value) => new Date(value).toLocaleString("cs-CZ"),
    },
    {
      field: "datumZaplaceni",
      headerName: "Datum zaplacení",
      width: 120,
      type: "date",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value) =>
        value ? new Date(value).toLocaleString("cs-CZ") : "",
    },
    {
      field: "cenaCelkem",
      headerName: "Cena",
      width: 70,
      type: "number",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value) => Number(value).toLocaleString("cs-CZ"),
    },
    {
      field: "zaplacenaCastka",
      headerName: "Zaplacená č.",
      width: 100,
      type: "number",
      getApplyQuickFilterFn: undefined,
      valueGetter: (value) => `${value || 0}`,
      valueFormatter: (value) => Number(value).toLocaleString("cs-CZ"),
    },
    {
      field: "mena",
      headerName: "Měna",
      width: 70,
      getApplyQuickFilterFn: undefined,
      valueGetter: (value, row) => `${row.zeme === 0 ? "CZK" : "EUR" || "CZK"}`,
    },
    {
      field: "zbyva",
      headerName: "Zbývá zaplatit",
      width: 90,
      type: "number",
      getApplyQuickFilterFn: undefined,
      valueGetter: (value) => `${value || 0}`,
      valueFormatter: (value) => Number(value).toLocaleString("cs-CZ"),
    },
    {
      field: "zaplaceno",
      headerName: "Zaplaceno",
      width: 70,
      type: "boolean",
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "pritomen",
      headerName: "Přítomen",
      width: 70,
      type: "boolean",
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "smazano",
      headerName: "Smazáno",
      width: 70,
      type: "boolean",
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "datumZmeny",
      headerName: "Datum změny",
      width: 120,
      type: "date",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value) => new Date(value).toLocaleString("cs-CZ"),
    },
    {
      field: "zmenil",
      headerName: "Změnil",
      width: 100,
    },
    {
      field: "zeme",
      headerName: "Země",
      width: 60,
      getApplyQuickFilterFn: undefined,
      valueGetter: (value) => `${value === 0 ? "ČR" : "SK" || "ČR"}`,
    },
    {
      field: 'deleteAction',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      width: 50,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          onClick={(e) => { e.stopPropagation(); handleDelete(parseInt(params.row.id)) }}
        >
          <DeleteIcon />
        </IconButton>
      ),
      disableExport: true
    }
  ];

  const filterData = (data: any) => {
    if (data === null) {
      return null;
    }
    return data.filter((row: any) => {
      if (present === 0 && payed === 0 && deleted === 0) {
        return true;
      }

      if (present === 1 && row.pritomen) {
        return false;
      }
      if (present === 2 && !row.pritomen) {
        return false;
      }

      if (payed === 1 && row.zaplaceno) {
        return false;
      }
      if (payed === 2 && !row.zaplaceno) {
        return false;
      }

      if (deleted === 1 && row.smazano) {
        return false;
      }
      if (deleted === 2 && !row.smazano) {
        return false;
      }

      return true;
    });
  };

  const resetFilters = () => {
    setPresent(DEFAULT_PRESENT);
    setPayed(DEFAULT_PAYED);
    setDeleted(DEFAULT_DELETED);
    setFilt([]);
  };

  const goHome = () => {
    navigate("/admin");
  }

  const handleGenerateLabels = () => {
    if (gridRef.current) {
      setIsPending(true);
      try {
        const variableSymbols: Number[] = gridFilteredSortedRowIdsSelector(
          gridRef
        ).map((id) => Number(gridRef.current.getRow(id).varSymbol));
        if (variableSymbols.length === 0) {
          setIsPending(false);
          enqueueSnackbar("Není zobrazen žádný záznam.", {
            variant: "error",
          });
          return;
        }
        openLabelPage(variableSymbols).then(() => setIsPending(false));
      } catch (e) {
        setIsPending(false);
        console.log(e);
        enqueueSnackbar("Nepodařilo se získat var. symboly.", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Nepodařilo se získat data z tabulky.", {
        variant: "error",
      });
    }
  };

  const handleRowSelection = (ids: any) => {
    const selectedIDs = ids;
    const selectedRowData = filteredData.filter((row: any) =>
      selectedIDs.includes(row.id)
    );

    if (selectedRowData.length > 0) {
      setSendButtonActive(true);
    } else {
      setSendButtonActive(false);
    }
    setSelectedRowIds(selectedIDs);
  };

  const isRowSelectable = (params: any) => {
    return !params.row.smazano;
  };

  const handleSendButtonClick = () => {
    fetch(
      "/api/Registration/formSend",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(selectedRowIds),
      }
    )
      .then((res) => {
        if (!res.ok) {
          enqueueSnackbar("Nepodařilo se poslat soubory.", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Vybrané PDF byly odeslány.", {
            variant: "success",
          });
        }
      });
  }

  const handlePresentButtonClick = () => {
    let isError = false;
    fetch(
      "/api/Registration/changePresent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(selectedRowIds),
      }
    )
      .then((res) => {
        if (!res.ok) {
          isError = true;
          return res.json();
        }
        return;
      })
      .then((d) => {
        getAll();
      })
      .then((d) => {
        if (!isError) {
          gridRef.current.setRowSelectionModel([]);
          enqueueSnackbar('Přítomnost u vybraných přihlášek změněna', { variant: 'success' });
        } else {
          enqueueSnackbar("Chyba při změně přítomnosti", { variant: 'error' });
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
      });
  }

  return (
    <div className="table-container">
      {(isPending || filteredData === null) && <div>Načítání...</div>}
      {!isPending && filteredData !== null && (
        <>
          <div className="button-container">
            <div className="filter-container">
              <TextField
                size="small"
                id="state"
                name="state"
                select
                label="Přítomen"
                style={{ textAlign: "left" }}
                value={present}
                onChange={(e) => setPresent(Number(e.target.value))}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                id="state"
                name="state"
                select
                label="Zaplaceno"
                style={{ textAlign: "left" }}
                value={payed}
                onChange={(e) => setPayed(Number(e.target.value))}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                id="state"
                name="state"
                select
                label="Smazáno"
                style={{ textAlign: "left" }}
                value={deleted}
                onChange={(e) => setDeleted(Number(e.target.value))}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Tooltip
                title="Resetuje filtry do původního nastavení"
                placement="bottom"
              >
                <Button
                  variant="contained"
                  size="small"
                  style={{ marginRight: "20px" }}
                  onClick={resetFilters}
                >
                  Reset
                </Button>
              </Tooltip>
            </div>
            <Tooltip
              title="Vygeneruje štítky všem přihláškám v tabulce"
              placement="bottom"
            >
              <Button
                variant="contained"
                size="small"
                style={{
                  marginLeft: "20px",
                  minWidth: "200px",
                  whiteSpace: "nowrap",
                }}
                onClick={handleGenerateLabels}
                disabled={isPending}
              >
                Vygenerovat štítky
              </Button>
            </Tooltip>
          </div>
          <DataGrid
            apiRef={gridRef}
            autoHeight
            checkboxSelection
            onRowSelectionModelChange={handleRowSelection}
            isRowSelectable={isRowSelectable}

            rows={filteredData}
            columns={columns}
            rowHeight={ROW_HEIGHT}
            pageSizeOptions={[TABLE_ROWS, 25, 50, 100]}
            //paginationModel={paginationModel}
            //onPaginationModelChange={setPaginationModel}
            //pageSizeOptions={[TABLE_ROWS]}
            //slots={{ toolbar: GridToolbar, pagination: CustomPagination }}
            slots={{ toolbar: GridToolbar }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: TABLE_ROWS,
                },
              },
            }}
            ignoreDiacritics
            slotProps={{
              toolbar: { showQuickFilter: true, csvOptions: { fileName: 'prihlasky', delimiter: ';', utf8WithBom: true } },
            }}
            filterModel={{
              items: filt,
              quickFilterValues: quickFilt,
            }}
            onFilterModelChange={(model) => {
              setFilt(model.items);
              setQuickFilt(model.quickFilterValues);
            }}
          />
        </>
      )}
      <div className="home-button--container">
        <div>
          {sendButtonActive && (<Button
            variant="contained"
            size="small"
            style={{ marginRight: "20px" }}
            onClick={handlePresentButtonClick}
          >
            Změnit přítomnost
          </Button>)}
        </div>
        <div >
          {sendButtonActive && (<Button
            variant="contained"
            size="small"
            style={{ marginRight: "20px" }}
            onClick={handleSendButtonClick}
          >
            Odeslat PDF
          </Button>)}
          <Button
            variant="contained"
            size="small"
            startIcon={<HomeIcon />}
            style={{
              marginLeft: "20px",
              minWidth: "200px",
              whiteSpace: "nowrap",
            }}
            onClick={goHome}
          >
            Domů
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationTable;
