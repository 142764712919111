import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import AdministrationHeader from "../administrationHeader";
import { useSnackbar } from "notistack";
import { nonNegativeIntegerLimited, selectOnFocus } from "../../../helpers/NumericFieldHelper";
import { Prices } from "../../form/formContainer";
import Food from "../../form/food";
import TotalPrice from "../../form/totalPrice";
import { useNavigate } from "react-router-dom";

const FormOnSite = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(true);

  const MINTIKETS = 0;
  const MAXTIKETS = 20;

  const [prices, setPrices] = useState<Prices>({
    bagette: 0,
    parking: 0,
    wholeDay: 0,
    wholeMultiday: 0,
    wholeAfter: 0,
    wholeBefore: 0,
    reducedDay: 0,
    reducedMultiday: 0,
    reducedAfter: 0,
    reducedBefore: 0,
    breakfast: 0,
    lunch: 0,
    dinner: 0,
  });

  const [form, setForm] = useState({
    multiday: 0,
    multidayReduced: 0,
    thursday: 0,
    thursdayReduced: 0,
    friday: 0,
    fridayReduced: 0,
    saturday: 0,
    saturdayReduced: 0,
    sunday: 0,
    sundayReduced: 0,
    children: 0,
    conferenceChildren: 0,
    thursdayParking: 0,
    fridayParking: 0,
    saturdayParking: 0,
    sundayParking: 0,

    thursdayBreakfast: 0,
    thursdayLunch: 0,
    thursdayDinner: 0,
    thursdayLunchBagette: 0,
    thursdayDinnerBagette: 0,
    fridayBreakfast: 0,
    fridayLunch: 0,
    fridayDinner: 0,
    fridayLunchBagette: 0,
    fridayDinnerBagette: 0,
    saturdayBreakfast: 0,
    saturdayLunch: 0,
    saturdayDinner: 0,
    saturdayLunchBagette: 0,
    saturdayDinnerBagette: 0,
    sundayBreakfast: 0,
    sundayLunch: 0,
    sundayLunchBagette: 0,
  });

  const [sumFoodPrice, setSumFoodPrice] = useState<number>(0);
  const [contribution, setContribution] = useState<number>(0);
  const [payment, setPayment] = useState({
    castka: 0,
    mena: "",
    typ: 0,
  });

  const [exchangeRate, setExchangeRate] = useState(25);
  const [accomodationId, setAccomodationId] = useState<number>(0);

  const [sumConferencePrice, setSumConferencePrice] = useState<number>(0);
  const [accomodationSum, setAccomodationSum] = useState({
    accomodation: 0,
    parking: 0,
    cityTax: 0,
  });
  const [sumTotalPrice, setSumTotalPrice] = useState<number>(0);

  useEffect(() => {
    setIsPending(true);

    fetch("/api/Registration/data", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Couldn not fetch operations for that resource!");
        }
        return res.json();
      })
      .then((data) => {
        setPrices(() => {
          var tmp = data?.costs[0];
          return {
            bagette: tmp.bageta,
            parking: tmp.parkovani,
            wholeDay: tmp.plnyDen,
            wholeMultiday: tmp.plnyPo,
            wholeAfter: tmp.plnyPo,
            wholeBefore: tmp.plnyPred,
            reducedDay: tmp.snizenyDen,
            reducedMultiday: tmp.snizenyPo,
            reducedAfter: tmp.snizenyPo,
            reducedBefore: tmp.snizenyPred,
            breakfast: tmp.snidane,
            lunch: tmp.obed,
            dinner: tmp.vecere,
          };
        });
        setExchangeRate(data?.exchangeRate?.konverze ?? 25);
        if (data?.accomodations) {
          setAccomodationId(data.accomodations[0].id);
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .then(() => {
        setIsPending(false);
      });
  }, []);


  useEffect(() => {
    setSumConferencePrice(
      form.multiday * prices.wholeMultiday +
      form.multidayReduced * prices.reducedMultiday +
      form.thursday * prices.wholeDay +
      form.thursdayReduced * prices.reducedDay +
      form.friday * prices.wholeDay +
      form.fridayReduced * prices.reducedDay +
      form.saturday * prices.wholeDay +
      form.saturdayReduced * prices.reducedDay +
      form.sunday * prices.wholeDay +
      form.sundayReduced * prices.reducedDay
    );

    setAccomodationSum({
      accomodation: 0,
      parking:
        form.thursdayParking * prices.parking +
        form.fridayParking * prices.parking +
        form.saturdayParking * prices.parking +
        form.sundayParking * prices.parking,
      cityTax: 0,
    });
  }, [form]);

  const goHome = () => {
    navigate("/admin");
  }

  const handleRegister = () => {    
    // setError(false);


    register();


  };

  const register = () => {
    // const sumEur = Math.round((sumTotalPrice / exchangeRate + Number.EPSILON) * 100) / 100;
    // if(payment.mena === 'CZK' ? payment.castka < sumTotalPrice : payment.castka < sumEur){
    if (payment.castka < sumTotalPrice) {
      if (window.confirm("Rozdíl přijaté a vrácené částky je menší než Celkem k úhradě.\nChcete pokračovat?") == false) {
        return
      }
    }
    setIsPending(true);
    const registrationData = setRegisterDataDTO();

    const paymentData = {
      varSymbol: 0,
      castka: payment.castka,
      mena: payment.mena,
      typ: payment.typ,
    };

    const data = { registration: registrationData, payment: paymentData };

    fetch("/api/Registration/createOnSite", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 400) {
            // setError(true);
            // fetchError = true;
            console.log(res);
            throw Error("Chyba při vytváření přihlášky");
          } else {
            throw Error("Could not fetch the data for that resource!");
          }
        }
        return res.json();
      })
      .then((data) => {
        enqueueSnackbar("Přihláška s variabilním symbolem " + data.varSymbol + " byla uložena", { variant: "success" });
        goHome();
      })
      .catch((err) => {
        setIsPending(false);
        enqueueSnackbar("Chyba při vytváření přihlášky", { variant: "error" });

        console.log(err);
        console.log(err.message);
      });
  };

  const setRegisterDataDTO = () => {
    const data = {
      token: "",
      id: 0,
      pritomen: true,
      varSymbol: null,
      jmeno: "Jednorázový",
      prijmeni: "Nákup",
      ulice: "x",
      mesto: "x",
      psc: 12345,
      telefon: "+420987654321",
      email: "x@x.com",
      poplatekPlny: form.multiday,
      poplatekSnizeny: form.multidayReduced,
      poplatek1DenPlnyCt: form.thursday,
      poplatek1DenSnizenyCt: form.thursdayReduced,
      poplatek1DenPlnyPa: form.friday,
      poplatek1DenSnizenyPa: form.fridayReduced,
      poplatek1DenPlnySo: form.saturday,
      poplatek1DenSnizenySo: form.saturdayReduced,
      poplatek1DenPlnyNe: form.sunday,
      poplatek1DenSnizenyNe: form.sundayReduced,
      deti15: form.children,
      detskaKonference: form.conferenceChildren,
      celkemPoplatky: sumConferencePrice,
      ubytovaniId: accomodationId,
      ubytovaniSt: 0,
      ubytovaniCt: 0,
      ubytovaniPa: 0,
      ubytovaniSo: 0,
      celkemUbytovani: 0,
      parkovaniCt: form.thursdayParking,
      parkovaniPa: form.fridayParking,
      parkovaniSo: form.saturdayParking,
      parkovaniNe: form.sundayParking,
      celkemParkovani: accomodationSum.parking,
      poplatekMB: 0,
      snidaneCt: form.thursdayBreakfast,
      obedCt: form.thursdayLunch,
      obedBagetaCt: form.thursdayLunchBagette,
      vecereBagetaCt: form.thursdayDinnerBagette,
      vecereTeplaCt: form.thursdayDinner,
      snidanePa: form.fridayBreakfast,
      obedPa: form.fridayLunch,
      obedBagetaPa: form.fridayLunchBagette,
      vecereBagetaPa: form.fridayDinnerBagette,
      vecereTeplaPa: form.fridayDinner,
      snidaneSo: form.saturdayBreakfast,
      obedSo: form.saturdayLunch,
      obedBagetaSo: form.saturdayLunchBagette,
      vecereBagetaSo: form.saturdayDinnerBagette,
      vecereTeplaSo: form.saturdayDinner,
      snidaneNe: form.sundayBreakfast,
      obedNe: form.sundayLunch,
      obedBagetaNe: form.sundayLunchBagette,
      celkemStravovani: sumFoodPrice,
      dobrovolnyPrispevek: contribution,
      dobrovolnyPrispevekDeti: 0,
      cenaCelkem: sumTotalPrice,
      smazano: false,
      smazalId: 0,
      zeme: 0,
      neposilatUpozorneni: false,
      ubytovaniOsoby: [],
      deti: Array.from({ length: form.children }, (v, i) => ({ jmeno: "x", vek: 7, dk: i < form.conferenceChildren ? true : false })),
      prednaskyPocet: [],
    };

    return data;
  };

  const validateNumberInput = (e: any, min: number, max: number) => {
    if (e.target.value === undefined || e.target.value === "") {
      setForm({
        ...form,
        [e.target.name]: 0,
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: nonNegativeIntegerLimited(e.target.value, min, max),
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {isPending && <div className="working">Pracuji ...</div>}
      <div className="administration" style={{ marginBottom: '20px' }}>
        <AdministrationHeader />
        <div className="administration-content" style={{ width: '1000px' }}>
          <h2>Prodej na místě</h2>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
          <div style={{ width: '1000px' }}>
            <Grid container spacing={3} style={{ textAlign: 'start' }}>
              <Grid item xs={4}>
                <span style={{ whiteSpace: 'nowrap' }}>Celokonferenční - plný ({prices.wholeMultiday},-)</span>
              </Grid>
              <Grid item xs={6}>
                <span style={{ whiteSpace: 'nowrap' }}>Celokonferenční - snížený ({prices.reducedMultiday},-)</span>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ paddingTop: '10px', textAlign: 'start' }}>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.wholeMultiday}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="multiday"
                  value={form.multiday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.reducedMultiday}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="multidayReduced"
                  value={form.multidayReduced}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ paddingTop: '15px', textAlign: 'start', fontWeight: 'bold' }}>Denní - plný ({prices.wholeDay},-)</div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                Čtvrtek
              </Grid>
              <Grid item xs={3}>
                Pátek
              </Grid>
              <Grid item xs={3}>
                Sobota
              </Grid>
              <Grid item xs={3}>
                Neděle
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.wholeDay}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="thursday"
                  value={form.thursday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.wholeDay}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="friday"
                  value={form.friday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.wholeDay}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="saturday"
                  value={form.saturday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.wholeDay}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="sunday"
                  value={form.sunday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <div style={{ paddingTop: '15px', textAlign: 'start', fontWeight: 'bold' }}>Denní - snížený ({prices.reducedDay},-)</div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                Čtvrtek
              </Grid>
              <Grid item xs={3}>
                Pátek
              </Grid>
              <Grid item xs={3}>
                Sobota
              </Grid>
              <Grid item xs={3}>
                Neděle
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.reducedDay}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="thursdayReduced"
                  value={form.thursdayReduced}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.reducedDay}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="fridayReduced"
                  value={form.fridayReduced}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.reducedDay}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="saturdayReduced"
                  value={form.saturdayReduced}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.reducedDay}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="sundayReduced"
                  value={form.sundayReduced}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: '15px', textAlign: 'start' }}>
              <Grid item xs={3}><b>Děti do 15 let (0,-)</b></Grid>
              <Grid item xs={6}>- z toho se účastní dětské konference</Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="children"
                  value={form.children}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="conferenceChildren"
                  value={form.conferenceChildren}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <div style={{ paddingTop: '15px', textAlign: 'start', fontWeight: 'bold' }}>Parkování ({prices.parking},-)</div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                Čtvrtek
              </Grid>
              <Grid item xs={3}>
                Pátek
              </Grid>
              <Grid item xs={3}>
                Sobota
              </Grid>
              <Grid item xs={3}>
                Neděle
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.parking}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="thursdayParking"
                  value={form.thursdayParking}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.parking}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="fridayParking"
                  value={form.fridayParking}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.parking}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="saturdayParking"
                  value={form.saturdayParking}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`x ${prices.parking}Kč`}
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MINTIKETS, MAXTIKETS);
                  }}
                  onFocus={selectOnFocus}
                  name="sundayParking"
                  value={form.sundayParking}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ width: '1000px' }}>
            <Food
              sum={sumFoodPrice}
              setSum={setSumFoodPrice}
              prices={prices}
              form={form}
              setForm={setForm}
            />
          </div>
          <div style={{ width: '1000px' }}>
            <TotalPrice
              editMode={true}
              sum={sumTotalPrice}
              setSum={setSumTotalPrice}
              exchangeRate={exchangeRate}
              contribution={contribution}
              setContribution={setContribution}
              conference={sumConferencePrice}
              accomodation={accomodationSum}
              food={sumFoodPrice}
              childContribution={0}
              state={0}
              toPay={undefined}
              payedAmount={undefined}
              variableSymbol={undefined}
              onSite={true}
              setPayment={setPayment}
            />
          </div>
          <div className="form--buttons-container" style={{ width: '300px' }}>
            <Button type="button" onClick={goHome} variant="contained">
              Zpět
            </Button>
            <Button
              type="button"
              onClick={handleRegister}
              variant="contained"
            >
              Odeslat
            </Button>
          </div>
        </div>

      </div>
    </>
  );
};

export default FormOnSite;
