import { Checkbox, Grid, MenuItem, TextField, Button } from "@mui/material";
import {
  nonNegativeIntegerLimited,
  selectOnFocus,
} from "../../../helpers/NumericFieldHelper";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { currencies } from "../../../helpers/CurrencyEnum";

const AddPaymentForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [variableSymbol, setVariableSymbol] = useState<string>("");
  const [amount, setAmount] = useState<any>(0);
  const [currency, setCurrency] = useState<number>(0);
  const [type, setType] = useState<boolean>(true);

  const [isPending, setIsPending] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (variableSymbol === "" || amount === 0) {
      enqueueSnackbar("Vyplňte všechny povinné údaje.", { variant: "error" });
      return;
    }

    const data = {
      varSymbol: variableSymbol,
      castka: amount,
      mena: currency === 0 ? "CZK" : "EUR",
      typ: Number(type),
    };

    setIsPending(true);
    let fetchError = false;
    fetch("/api/Payment/add", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          fetchError = true;
          return response.json();
        }
        return response;
      })
      .then((data) => {
        if (fetchError) {
          enqueueSnackbar(data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Platba uložena.", { variant: "success" });
          setVariableSymbol("");
          setAmount(0);
          setCurrency(0);
          setType(true);
        }
        setIsPending(false);
      });
  };

  return (
    <div className="add-payment-form--container">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6} mt={0.5}>
              <span className="payment-label"> Variabilní symbol </span>
            </Grid>
            <Grid item xs={6}>
              <div className="line">
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Var. symbol`}
                  type="number"
                  required
                  onBlur={(e) => {
                    setVariableSymbol(
                      nonNegativeIntegerLimited(
                        e.target.value,
                        0,
                        1000000
                      ).toString()
                    );
                  }}
                  onFocus={selectOnFocus}
                  name="variableSymbol"
                  value={variableSymbol}
                  onChange={(e) => setVariableSymbol(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6} mt={0.5}>
              <span className="payment-label"> Zaplacená částka </span>
            </Grid>
            <Grid item xs={6}>
              <div className="line">
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Zaplacená částka`}
                  type="number"
                  required 
                  onBlur={(e) => {
                    setAmount(
                      nonNegativeIntegerLimited(e.target.value, -100000, 1000000, true)
                    );
                  }}
                  onFocus={selectOnFocus}
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  size="small"
                  id="currency"
                  name="currency"
                  select
                  label="Měna"
                  style={{ textAlign: "left", minWidth: "100px" }}
                  value={currency}
                  onChange={(e) => setCurrency(Number(e.target.value))}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6} mt={0.5}>
              <span className="payment-label"> Hotovost </span>
            </Grid>
            <Grid item xs={6}>
              <div className="line">
                <Checkbox
                  name="type"
                  value={type}
                  checked={type}
                  onChange={(e) => setType(e.target.checked)}
                />
                <span>v případě nezatrhnutí jde o bankovní převod</span>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Button variant="contained" type="submit" disabled={isPending}>
                Odeslat
              </Button>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddPaymentForm;
