import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import AdministrationHeader from "../administrationHeader";

import "./login.css";
import { Button } from "@mui/material";
const Login = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [fieldError, setFieldError] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();
    
    const handleLogin = (e: any) => {
        e.preventDefault();

        const data = {
            username: username,
            password: password,
        };

        fetch(`api/Auth/login`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp.token) {
                if (localStorage.getItem("userToken")) {
                    localStorage.removeItem("userToken");
                }
                localStorage.setItem("userToken", resp.token);

                if (localStorage.getItem("userRole")) {
                    localStorage.removeItem("userRole");
                }
                localStorage.setItem("userRole", resp.role);

                if (localStorage.getItem("userName")) {
                    localStorage.removeItem("userName");
                }
                localStorage.setItem("userName", resp.username);

                if (localStorage.getItem("userFirstName")) {
                    localStorage.removeItem("userFirstName");
                }
                localStorage.setItem("userFirstName", resp.name);

                if (localStorage.getItem("userLastName")) {
                    localStorage.removeItem("userLastName");
                }
                localStorage.setItem("userLastName", resp.surname);

                if (localStorage.getItem("userID")) {
                    localStorage.removeItem("userID");
                }
                localStorage.setItem("userID", resp.id);

                enqueueSnackbar("Přihlášení probělo úspěšně", { variant: 'success' })
                setTimeout(function () {
                    window.location.href = "/admin";
                }, 1000);
            }else{
                enqueueSnackbar("Špatné uživatelské jméno nebo heslo", { variant: 'error' });
            }
        })
        .catch((e) => {
            enqueueSnackbar("Špatné uživatelské jméno nebo heslo", { variant: 'error' });
        })
    }

    return ( 
    <div className="login-container">
        <AdministrationHeader />
        <form onSubmit={handleLogin}>
            <div className="login-container--form">
            <TextField
                autoFocus
                error={fieldError}
                required
                id="login"
                name="login"
                label="Přihlašovací jméno"
                type="text"
                fullWidth
                variant="standard"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
                error={fieldError}
                required
                id="password"
                name="password"
                label="Heslo"
                type="password"
                fullWidth
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div className="login-container--form-button">
                <Button type="submit" variant="contained">
                    Přihlásit se
                </Button>
            </div>
            
            </div>
        </form>
    </div>
    );
}
 
export default Login;