import UnconfirmedPaymentTable from "./unconfirmedPaymentTable";
import AdministrationHeader from "../administrationHeader";
import "../administration.css";

const UnconfirmedPaymentOverview = () => {
  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="unconfirmed-content administration-content table">
        <h2>Nepotvrzené platby</h2>

        <UnconfirmedPaymentTable />
      </div>
    </div>
  );
};

export default UnconfirmedPaymentOverview;
