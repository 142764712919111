import { Grid, MenuItem, TextField, Checkbox } from "@mui/material";
import "./subform.css";
import "./conferencePrice.css";
import { Prices, PersonAccomodation } from "./formContainer";
import { useEffect, useState } from "react";
import {
  nonNegativeIntegerLimited,
  selectOnFocus,
} from "../../helpers/NumericFieldHelper";
import { ZipRegexInput } from "../../helpers/RegexValidateHelper";

const MIN_NIGHTS = 1;
const MAX_NIGHTS = 4;

const MIN_ACCOMODATION_GUESTS = 0;
const MAX_ACCOMODATION_GUESTS = 20;

const MIN_PARKING = 0;
const MAX_PARKING = 20;

const Accomodation = ({
  validateRequired,
  validateZip,
  sum,
  setSum,
  accomodations,
  prices,
  form,
  setForm,
}: {
  validateRequired: (value: string) => boolean;
  validateZip: (value: string) => boolean;
  sum: {
    accomodation: number;
    parking: number;
    cityTax: number;
  };
  setSum: React.SetStateAction<any>;
  accomodations: any;
  prices: Prices;
  form: any;
  setForm: React.SetStateAction<any>;
}) => {
  const [max, setMax] = useState(0);
  const calculateSum = () => {
    let accSum =
      (form.thursday * accomodations[form.accomodation]?.cenaNoc ?? 0) +
      (form.friday * accomodations[form.accomodation]?.cenaNoc ?? 0) +
      (form.saturday * accomodations[form.accomodation]?.cenaNoc ?? 0) +
      (form.sunday * accomodations[form.accomodation]?.cenaNoc ?? 0);
    setSum({
      accomodation: isNaN(accSum) || accSum === undefined ? 0 : accSum,
      parking:
        form.thursdayParking * prices.parking +
        form.fridayParking * prices.parking +
        form.saturdayParking * prices.parking +
        form.sundayParking * prices.parking,
      cityTax: cityTax(),
    });
  };

  const cityTax = () => {
    var sum = 0;
    for (var i = 0; i < form.persons.length; i++) {
      if (form.persons[i].eighteen === true) {
        sum +=
          Number(form.persons[i].nights) *
          Number(
            accomodations[form.accomodation] !== undefined
              ? accomodations[form.accomodation].poplatekMB ?? 0
              : 0
          );
      }
    }
    return sum;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };

  const handleChangePersons = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let data: PersonAccomodation[] = [...form.persons];
    data[index] = {
      ...data[index],
      [event.target.name]: event.target.value,
    };
    setForm({ ...form, persons: data });
  };

  const handleChangeBooleanPersons = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let data: PersonAccomodation[] = [...form.persons];
    data[index] = {
      ...data[index],
      [event.target.name]: event.target.checked,
    };
    setForm({ ...form, persons: data });
  };

  const validateNumberInput = (e: any, min: number, max: number) => {
    if (e.target.value === undefined || e.target.value === "") {
      setForm({
        ...form,
        [e.target.name]: 0,
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: nonNegativeIntegerLimited(e.target.value, min, max),
      });
    }
  };

  const validateNumberInputIndexed = (
    e: any,
    index: number,
    min: number,
    max: number
  ) => {
    let res = 0;
    if (e.target.value === undefined || e.target.value === "") {
      res = 0;
    } else {
      res = nonNegativeIntegerLimited(e.target.value, min, max);
    }

    let data: PersonAccomodation[] = [...form.persons];
    data[index] = {
      ...data[index],
      [e.target.name]: res,
    };
    setForm({ ...form, persons: data });
  };

  useEffect(() => {
    calculateSum();
    var maximumNight = Math.max(
      form.thursday,
      form.friday,
      form.saturday,
      form.sunday
    );
    setMax(maximumNight);

    if ((form.persons as []).length < maximumNight) {
      var persons: PersonAccomodation[] = form.persons;
      for (var i = (form.persons as []).length; i < maximumNight; i++) {
        persons.push({
          firstName: "",
          lastName: "",
          street: "",
          city: "",
          zip: "",
          eighteen: Boolean(true),
          nights: 0,
        });
      }
      setForm({ ...form, persons: persons });
      console.log(persons);
    } else if ((form.persons as []).length > maximumNight) {
      var persons: PersonAccomodation[] = form.persons;
      for (var j = (form.persons as []).length; j > maximumNight; j--) {
        persons.pop();
      }
      setForm({ ...form, persons: persons });
    }
  }, [form]);

  return (
    <>
      <div className="subform">
        <h2 className="subform-header">Ubytování</h2>
        <p className="subform-note">
          Některá ubytování je nutné objednat minimálně na dvě noci.
        </p>
        <div className="accomodationFormContainer">
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <p>Místo</p>
              </Grid>
              <Grid item xs={8}>
                <p>Počet lůžek</p>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <p>Název (cena/noc/lůžko)</p>
              </Grid>
              <Grid item xs={2}>
                <p>St/Čt</p>
              </Grid>
              <Grid item xs={2}>
                <p>Čt/Pá</p>
              </Grid>
              <Grid item xs={2}>
                <p>Pá/So</p>
              </Grid>
              <Grid item xs={2}>
                <p>So/Ne</p>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  style={{ width: "100%", textAlign: "left" }}
                  name="accomodation"
                  select
                  required
                  error={validateRequired(form.accomodation)}
                  label="Ubytování"
                  value={form.accomodation}
                  onChange={handleChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: accomodations[form.accomodation].zaplnene ? 'red' : 'inherit'
                    }
                  }}
                >
                  {accomodations.map((option: any, index: number) => (
                    <MenuItem 
                      key={index} 
                      value={index}
                      style={{ color: option.zaplnene ? 'red' : 'inherit' }}
                      >
                      {option.nazev + ", " + option.cenaNoc + " Kč"}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(
                      e,
                      MIN_ACCOMODATION_GUESTS,
                      MAX_ACCOMODATION_GUESTS
                    );
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="thursday"
                  value={form.thursday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(
                      e,
                      MIN_ACCOMODATION_GUESTS,
                      MAX_ACCOMODATION_GUESTS
                    );
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="friday"
                  value={form.friday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(
                      e,
                      MIN_ACCOMODATION_GUESTS,
                      MAX_ACCOMODATION_GUESTS
                    );
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="saturday"
                  value={form.saturday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(
                      e,
                      MIN_ACCOMODATION_GUESTS,
                      MAX_ACCOMODATION_GUESTS
                    );
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="sunday"
                  value={form.sunday}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={8} mt={0.5}>
                <span style={{ textAlign: "right" }}>
                  {" "}
                  Celkem za ubytování{" "}
                </span>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Celkem Kč`}
                  type="number"
                  name="sum"
                  value={sum.accomodation}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingTop: "10px" }}>
          Podrobnější informace o ubytování naleznete{" "}
          <a href="https://konferencecharis.cz/#informace" target="_blank">
            zde
          </a>
          .
        </div>
      </div>
      {max > 0 && (
        <div className="subform">
          <h2 className="subform-header">Seznam ubytovaných</h2>
          {/*<p className="subform-note">
            + Dovršený věk k 10.7.2024 méně než 18, osoby ZTP/P a jejich
            doprovod.
      </p>*/}

          <div className="cityTaxFormContainer">
            <Grid container spacing={2}>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={1.8}>
                  <p>Jméno</p>
                </Grid>
                <Grid item xs={2}>
                  <p>Příjmení</p>
                </Grid>
                <Grid item xs={2}>
                  <p>Ulice</p>
                </Grid>
                <Grid item xs={2}>
                  <p>Město</p>
                </Grid>
                <Grid item xs={1.8}>
                  <p>PSČ</p>
                </Grid>
                <Grid item xs={1.1}>
                  <p>Dospělý</p>
                </Grid>
                <Grid item xs={1.3}>
                  <p>Počet nocí</p>
                </Grid>
              </Grid>
              {form.persons.map((person: PersonAccomodation, index: number) => (
                <Grid container item xs={12} spacing={1} key={index}>
                  <Grid item xs={1.8}>
                    <TextField
                      size="small"
                      type="text"
                      name="firstName"
                      label="Jméno"
                      required
                      error={validateRequired(person.firstName)}
                      value={person.firstName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangePersons(index, e)
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      type="text"
                      name="lastName"
                      label="Přijmení"
                      required
                      error={validateRequired(person.lastName)}
                      value={person.lastName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangePersons(index, e)
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      type="text"
                      name="street"
                      label="Ulice a č.p."
                      required
                      error={validateRequired(person.street)}
                      value={person.street}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangePersons(index, e)
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      type="text"
                      name="city"
                      label="Město"
                      required
                      error={validateRequired(person.city)}
                      value={person.city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangePersons(index, e)
                      }
                    />
                  </Grid>
                  <Grid item xs={1.8}>
                    <TextField
                      size="small"
                      type="text"
                      name="zip"
                      label="PSČ"
                      required
                      error={
                        validateRequired(person.zip) || validateZip(person.zip)
                      }
                      inputProps={{
                        pattern: ZipRegexInput,
                      }}
                      value={person.zip}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangePersons(index, e)
                      }
                    />
                  </Grid>
                  <Grid item xs={1.1}>
                    <div>
                      <Checkbox
                        size="small"
                        name="eighteen"
                        value={Boolean(person.eighteen)}
                        checked={Boolean(person.eighteen)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeBooleanPersons(index, e)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={1.3}>
                    <TextField
                      size="small"
                      type="number"
                      onBlur={(e) => {
                        validateNumberInputIndexed(
                          e,
                          index,
                          MIN_NIGHTS,
                          MAX_NIGHTS
                        );
                      }}
                      onFocus={selectOnFocus}
                      name="nights"
                      label="Počet"
                      required
                      error={validateRequired(person.nights.toString())}
                      value={person.nights}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangePersons(index, e)
                      }
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid container item xs={12} spacing={3} mt={0.1} mb={1}>
                <Grid item xs={8}>
                  <span style={{ textAlign: "right" }}>
                    {" "}
                    Poplatek města Brna (
                    {accomodations[form.accomodation]?.poplatekMB ?? 0}Kč /
                    dospělá osoba / noc){" "}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    id="outlined-number"
                    label={`Celkem Kč`}
                    type="number"
                    name="sum"
                    value={sum.cityTax}
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      <div className="subform">
        <h2 className="subform-header">Parkování</h2>
        <p className="subform-note">
          Platí i pro noční stání, mezi 22:00 a 7:30 není přístup k autu.
        </p>
        <div className="accomodationFormContainer">
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <p>Počet míst</p>
              </Grid>
              <Grid item xs={2}>
                <p>Čt</p>
              </Grid>
              <Grid item xs={2}>
                <p>Pá</p>
              </Grid>
              <Grid item xs={2}>
                <p>So</p>
              </Grid>
              <Grid item xs={2}>
                <p>Ne</p>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={4}>
                <span>V areálu BVV ({prices.parking}Kč / celý den)</span>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MIN_PARKING, MAX_PARKING);
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="thursdayParking"
                  value={form.thursdayParking}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MIN_PARKING, MAX_PARKING);
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="fridayParking"
                  value={form.fridayParking}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MIN_PARKING, MAX_PARKING);
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="saturdayParking"
                  value={form.saturdayParking}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  onBlur={(e) => {
                    validateNumberInput(e, MIN_PARKING, MAX_PARKING);
                  }}
                  onFocus={selectOnFocus}
                  label="Počet"
                  name="sundayParking"
                  value={form.sundayParking}
                  onChange={handleChange}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={8} mt={0.5}>
                <span style={{ textAlign: "right" }}>
                  {" "}
                  Celkem za parkování{" "}
                </span>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Celkem Kč`}
                  type="number"
                  name="sum"
                  value={sum.parking}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Accomodation;
