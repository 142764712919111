import { useEffect, useState } from "react";
import {
  DataGrid, GridColDef, GridToolbarContainerProps,
  GridToolbarContainer, GridToolbarExport, GridToolbarExportContainer,
  GridCsvExportMenuItem, GridPrintExportMenuItem
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import GridExcelExportMenuItem from "../../common/gridExcelExportMenuItem";

const ROW_HEIGHT = 32;
const columns: GridColDef[] = [
  { field: "varSymbol", headerName: "Var.symbol", width: 90 },
  {
    field: "pritomen",
    headerName: "Přítomen",
    width: 100,
    type: "boolean"
  },
  {
    field: "jmeno",
    headerName: "Jméno",
    width: 170,
  },
  {
    field: "prijmeni",
    headerName: "Příjmení",
    width: 170,
  },
  {
    field: "ubytovani",
    headerName: "Ubytování",
    width: 150,
  },
  {
    field: "ubytovaniSt",
    headerName: "Ubytování St",
    type: "number",
    width: 120,
  },
  {
    field: "ubytovaniCt",
    headerName: "Ubytování Čt",
    type: "number",
    width: 120,
  },
  {
    field: "ubytovaniPa",
    headerName: "Ubytování Pá",
    type: "number",
    width: 120,
  },
  {
    field: "ubytovaniSo",
    headerName: "Ubytování So",
    type: "number",
    width: 120,
  },
  {
    field: "poplatekMB",
    headerName: "Poplatek MB",
    type: "number",
    width: 120,
  },
  {
    field: "poplatekMBPocet",
    headerName: "Počet poplatků MB",
    type: "number",
    width: 150,
  },
];

const AccomodationReportTable = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    setIsPending(true);
    fetch("/api/Accomodation/getReport", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data) => {
        setData(data.accomodations);
        setIsPending(false);
      })
      .catch((err) => {
        setError(true);
        setIsPending(false);
        enqueueSnackbar("Chyba při načítání dat", { variant: "error" });
        console.log(err.message);
      });
  }, []);

  const csvOptions = {
    fileName: 'ubytovani',
    delimiter: ';',
    utf8WithBom: true,
  };

  function CustomToolbar(props: GridToolbarContainerProps) {
    return (<GridToolbarContainer {...props}>
      <GridToolbarExportContainer>
        <GridExcelExportMenuItem columns={columns} fileName="ubytovani" />
        <GridCsvExportMenuItem options={csvOptions} />
        <GridPrintExportMenuItem />
      </GridToolbarExportContainer>
    </GridToolbarContainer>);
  }

  return (
    <div className="table-container">
      {(isPending || data === null) && <div>Načítání...</div>}
      {error && <div>Chyba při načítání dat</div>}
      {!isPending && data !== null && !error && (
        <DataGrid
          getRowId={(row) => row.varSymbol}
          rows={data}
          columns={columns}
          rowHeight={ROW_HEIGHT}
          slots={{ toolbar: CustomToolbar }}
          pageSizeOptions={[100]}
        />
      )}
    </div>
  );
};

export default AccomodationReportTable;
