import { MenuItem, TextField } from "@mui/material";
import "./basicInformation.css";
import "./subform.css";
import { EmailRegexInput, PhoneRegexInput, ZipRegexInput } from "../../helpers/RegexValidateHelper";

const countries = [
  {
    value: 0,
    label: "ČR (Platba v CZK)",
  },
  {
    value: 1,
    label: "Slovensko (Platba v EUR)",
  },
];

const BasicInformation = ({
  validatePhone,
  validateEmail,
  validateZip,
  validateRequired,
  basicInformation,
  setBasicInformation,
}: {
  validatePhone: (value: string) => boolean;
  validateEmail: (value: string) => boolean;
  validateZip: (value: string) => boolean;
  validateRequired: (value: string) => boolean;
  basicInformation: any;
  setBasicInformation: React.SetStateAction<any>;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicInformation({
      ...basicInformation,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="subform">
      <h2 className="subform-header">Osobní údaje</h2>

      <div className="basicInformationFormContainer">
        <TextField
          size="small"
          type="text"
          name="firstName"
          label="Jméno"
          required
          error={validateRequired(basicInformation.firstName)}
          value={basicInformation.firstName}
          onChange={handleChange}
        />
        <TextField
          size="small"
          type="text"
          name="lastName"
          label="Přijmení"
          required
          error={validateRequired(basicInformation.lastName)}
          value={basicInformation.lastName}
          onChange={handleChange}
        />
        <TextField
          size="small"
          type="text"
          name="street"
          label="Ulice a č.p."
          required
          error={validateRequired(basicInformation.street)}
          value={basicInformation.street}
          onChange={handleChange}
        />
        <TextField
          size="small"
          type="text"
          name="city"
          label="Město"
          required
          error={validateRequired(basicInformation.city)}
          value={basicInformation.city}
          onChange={handleChange}
        />
        <TextField
          size="small"
          type="text"
          name="zip"
          label="PSČ"
          required
          error={
            validateRequired(basicInformation.zip) ||
            validateZip(basicInformation.zip)
          }
          inputProps={{
            pattern: ZipRegexInput,
          }}
          value={basicInformation.zip}
          onChange={handleChange}
        />
        <TextField
          size="small"
          id="state"
          name="state"
          required
          error={validateRequired(basicInformation.state)}
          select
          label="Země"
          style={{ textAlign: "left" }}
          value={basicInformation.state}
          onChange={handleChange}
        >
          {countries.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          size="small"
          type="phone"
          name="telefon"
          label="Telefonní číslo"
          required
          error={
            validateRequired(basicInformation.telefon) ||
            validatePhone(basicInformation.telefon)
          }
          inputProps={{
            pattern: PhoneRegexInput,
          }}
          value={basicInformation.telefon}
          onChange={handleChange}
        />
        <TextField
          size="small"
          type="email"
          name="email"
          label="E-mail"
          required
          error={
            validateRequired(basicInformation.email) ||
            validateEmail(basicInformation.email)
          }
          inputProps={{
            pattern: EmailRegexInput,
          }}
          value={basicInformation.email}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default BasicInformation;
