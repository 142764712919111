import Footer from "../common/footer";
import AdminLandingPageContent from "./adminLandingPageContent";
import AdministrationHeader from "./administrationHeader";

const AdminLandingPage = () => {
  return (
    <>
      <AdministrationHeader />

      <AdminLandingPageContent />

      <Footer>
        <div></div>
      </Footer>
    </>
  );
};

export default AdminLandingPage;


