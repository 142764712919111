import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext<any>(null);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState(localStorage.getItem("userToken"));

  // Function to set the authentication token
  const setToken = (newToken: any) => {
    setToken_(newToken);
  };

  useEffect(() => {
    const checkTokenValidity = () => {
      let token = localStorage.getItem("userToken");
      if (!token) {
        setTimeout(function () {
          window.location.href = "/login";
        }, 100);
        return;
      }
      let decodedToken = jwtDecode(token as string);
      let currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp && decodedToken.exp * 1000 < currentDate.getTime()) {
        console.log("Token expired.");
        localStorage.clear();
        window.location.href = "/login";
        window.location.reload();
      }
    };

    // Check token validity right after component load
    checkTokenValidity();

    // Then check every 30 seconds
    const interval = setInterval(checkTokenValidity, 30000);

    return () => clearInterval(interval);
  }, []);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
    }),
    [token]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
