import { Button, TextField } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { nonNegativeIntegerLimited } from "../../../helpers/NumericFieldHelper";

const TABLE_ROWS = 15;
const ROW_HEIGHT = 32;

const NonpayedTable = () => {
  const [data, setData] = useState<any>(null);
  const [dataFiltered, setDataFiltered] = useState<any>(null);
  const [isDataFiltered, setIsDataFiltered] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [days, setDays] = useState<string>("");
  const [selectedVarSymbols, setSelectedVarSymbols] = useState<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsPending(true);
    fetch("/api/Registration/getAllNoNPayed", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        console.log(res);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setData(data.registrations);
        setDataFiltered(data.registrations);
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const columns: GridColDef[] = [
    //{ field: "id", headerName: "ID", width: 40 },
    {
      field: "varSymbol",
      headerName: "Var. symbol",
      width: 90,
    },
    {
      field: "prijmeni",
      headerName: "Příjmení",
      width: 100,
    },
    {
      field: "jmeno",
      headerName: "Jméno",
      width: 100,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "telefon",
      headerName: "Telefon",
      width: 100,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "email",
      headerName: "Email",
      width: 100,
    },
    {
      field: "datumObjednani",
      headerName: "Datum objednání",
      width: 120,
      type: "date",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value) => new Date(value).toLocaleString("cs-CZ"),
    },
    {
      field: "datumZaplaceni",
      headerName: "Datum zaplacení",
      width: 120,
      type: "date",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value) =>
        value ? new Date(value).toLocaleString("cs-CZ") : "",
    },
    {
      field: "cenaCelkem",
      headerName: "Cena",
      width: 70,
      type: "number",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value) => Number(value).toLocaleString("cs-CZ"),
    },
    {
      field: "zaplacenaCastka",
      headerName: "Zaplacená č.",
      width: 100,
      type: "number",
      getApplyQuickFilterFn: undefined,
      valueGetter: (value) => `${value || 0}`,
      valueFormatter: (value) => Number(value).toLocaleString("cs-CZ"),
    },
    {
      field: "mena",
      headerName: "Měna",
      width: 70,
      getApplyQuickFilterFn: undefined,
      valueGetter: (value, row) => `${row.zeme === 0 ? "CZK" : "EUR" || "CZK"}`,
    },
    {
      field: "zbyva",
      headerName: "Zbývá zaplatit",
      width: 90,
      type: "number",
      getApplyQuickFilterFn: undefined,
      valueGetter: (value) => `${value || 0}`,
      valueFormatter: (value) => Number(value).toLocaleString("cs-CZ"),
    },
    {
      field: "zaplaceno",
      headerName: "Zaplaceno",
      width: 70,
      type: "boolean",
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "smazano",
      headerName: "Smazáno",
      width: 70,
      type: "boolean",
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "datumZmeny",
      headerName: "Datum změny",
      width: 120,
      type: "date",
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value) => new Date(value).toLocaleString("cs-CZ"),
    },
    {
      field: "zeme",
      headerName: "Země",
      width: 60,
      getApplyQuickFilterFn: undefined,
      valueGetter: (value) => `${value === 0 ? "ČR" : "SK" || "ČR"}`,
    },
  ];

  const handleShowSelected = () => {
    if (days === "") {
      enqueueSnackbar("Zadejte počet dnů", { variant: "error" });
      return;
    }
    if (!isDataFiltered) {
      setIsDataFiltered(true);
      const selected = data.filter(
        (row: any) =>
          new Date(row.datumZmeny).getTime() <
          new Date().getTime() - Number(days) * 24 * 60 * 60 * 1000
      );
      setDataFiltered(selected);
    } else {
      setIsDataFiltered(false);
      setDataFiltered(data);
    }
  };

  const handleSendMail = () => {
    setIsSending(true);

    const data = { varSymbols: selectedVarSymbols };
    console.log(JSON.stringify(data));

    let fetchError = false;
    fetch("/api/Registration/sendReminder", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          fetchError = true;
        }
        return res.json();
      })
      .then((data) => {
        if (fetchError) {
          enqueueSnackbar(data.message, { variant: "error" });
        } else {
          enqueueSnackbar(data.message, { variant: "success" });
        }
        setIsSending(false);
      });
  };

  return (
    <div className="table-container">
      {isPending || data === null ? (
        <div>Načítání...</div>
      ) : (
        <>
          <div className="table-header">
            <h3>Zaslání mailu s informací o výši doplatku:</h3>
            <div className="table-header--search">
              <TextField
                size="small"
                type="number"
                name="days"
                label="Počet dnů"
                required
                onBlur={(e) => {
                  setDays(
                    nonNegativeIntegerLimited(e.target.value, 0, 365).toString()
                  );
                }}
                onChange={(e) => {
                  setDays(e.target.value);
                }}
                value={days}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleShowSelected}
                disabled={isSending}
              >
                Zobrazit {isDataFiltered ? "vše" : "vybrané"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleSendMail}
                disabled={isSending}
              >
                Odeslat mail
              </Button>
            </div>
            <p>
              (Odešle mail všem označeným, kteří mají nedoplatek a provedli
              přihlášení nebo byli upozorněni před zadaným počtem dnů.)
            </p>
          </div>
          <DataGrid
            autoHeight
            rows={dataFiltered}
            columns={columns}
            rowHeight={ROW_HEIGHT}
            checkboxSelection
            disableRowSelectionOnClick
            pageSizeOptions={[TABLE_ROWS, 25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: TABLE_ROWS,
                },
              },
            }}
            ignoreDiacritics
            slotProps={{
              toolbar: { showQuickFilter: true },
            }}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowSymbols = data
                .filter((row: any) => selectedIDs.has(row.id))
                .map((row: any) => Number(row.varSymbol));
              console.log(selectedRowSymbols);
              setSelectedVarSymbols(selectedRowSymbols);
            }}
          />
        </>
      )}
    </div>
  );
};

export default NonpayedTable;
