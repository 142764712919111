import Footer from "../common/footer";
import Header from "../common/header";

const RegistrationEndedPage = () => {
  return (
    <>
      <Header>
        <div style={{ textDecoration: 'none', color: 'initial', display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 10}}>
          <img src="/Charis_512.png" alt="Charis Logo" style={{ width: '100px', height: 'auto', marginRight: '20px'}} />
          <div style={{display: "flex", flexDirection: "column"}}>
            <h1 style={{ margin: 0, textAlign: "left" }}> Konference Charis 2024 - administrace </h1>
            <h3 style={{ marginTop: 10, textAlign: "left" }}>Brno výstaviště 10. - 14. 7. 2024</h3>
          </div>
        </div>
      </Header>

      <h1>Přihlašování bylo ukončeno.</h1>

      <Footer>
        <hr></hr>
        <div>Registrační systém pro konferenci <a href="https://konferencecharis.cz/" target="_blank">Charis</a> tvoří a provozuje <a href="http://www.icontio.com" target="_blank">Icontio</a>.</div>
        <div style={{padding: "10px 0"}}><a href="http://www.icontio.com" target="_blank"><img src="In365IcoLogo.png" alt="icontio.com" height="35"></img></a></div>
      </Footer>
    </>
  );
};

export default RegistrationEndedPage;
