import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const WarningDialog = ({
  open,
  setOpen,
  Message,
  Header,
  Action,
}: {
  open: boolean;
  setOpen: any;
  Message: string;
  Header: string;
  Action: any;
}) => {
  const handleClose = () => {
    console.log("closing");
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ color: "red" }}>{Header}</DialogTitle>
      <DialogContent>
        <DialogContentText>{Message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Zavřít
        </Button>
        <Button
          onClick={() => {
            Action();
            handleClose();
          }}
          color="primary"
        >
          Pokračovat
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
