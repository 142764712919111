import { LabelData } from "../../../types/RegistrationTypes";
import "./label";

function max(a: Number, b: Number, c: Number, d: Number) {
  return Math.max.apply(null, Array.from(arguments));
}

function min(a: Number, b: Number, c: Number, d: Number) {
  return Math.min.apply(null, Array.from(arguments));
}

const Label = ({ label, qrcode }: { label: LabelData; qrcode: any }) => {
  interface LabelDataIndexable extends LabelData {
    [key: string]: any;
  }

  const renderDays = (name: string, arg: string = "") => {
    const days = ["Ct", "Pa", "So", "Ne"];
    if(arg === ""){
      return days.map((day) => (
        <td key={day}>
          {(label as LabelDataIndexable)[`${name}${day}`] === 0
            ? ""
            : (label as LabelDataIndexable)[`${name}${day}`]}
        </td>
      ));
    } else {
      return days.map((day) => {
        const sum = (label as LabelDataIndexable)[`${name}${day}`] + (label as LabelDataIndexable)[`${arg}${day}`];
        return (
          <td key={day}>
            {sum === 0
              ? ""
              : sum}
          </td>
        );
      });
    }     
  }

  const isAccomodated = (label: LabelData): boolean => {
    if((label.ubytovaniCt + label.ubytovaniPa + label.ubytovaniSo + label.ubytovaniSt) > 0){
      return true;
    }
    return false;
  }

  return (
    <div className="conference-label">
      <table cellSpacing="0" border={0} style={{ borderCollapse: "collapse" }}>
        <tbody>
          <tr>
            <td>
              <div className="label-div">
                <h2 className="label-border">
                  <span>
                    <b> VS: {label.varSymbol} | </b>
                  </span>
                  <span>Katolická charismatická konference 2024</span>
                </h2>
                <br />
                <table border={0} width="460px">
                  <tbody>
                    <tr>
                      <td>
                        <b>Číslo přihlášky: {label.varSymbol}</b>
                      </td>
                      <td>
                        Datum přihlášení:{" "}
                        {new Date(label.datumObjednani).toLocaleDateString()}
                      </td>
                    </tr>
                    <tr>
                      <td>Jméno: {label.jmeno}</td>
                      <td>
                        Datum zaplacení:{" "}
                        {new Date(label.datumZaplaceni).toLocaleDateString()}
                      </td>
                    </tr>
                    <tr>
                      <td>Příjmení: {label.prijmeni}</td>
                      <td>
                        Adresa: {label.mesto} {label.zeme === 0 ? "ČR" : "SK"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={8} style={{ borderTop: "solid 1px black" }}>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ borderBottom: "solid 1px black" }}
                      >
                        <table border={0} width="440px" className="label-table">
                          <tbody>
                            <tr>
                              <td>Ubytování</td>
                              <td width="45">ST</td>
                              <td width="45">ČT</td>
                              <td width="45">PÁ</td>
                              <td width="45">SO</td>
                            </tr>
                            <tr>
                              <td>
                                Místo:{" "}
                                {isAccomodated(label) && <b>
                                  <i>{label.ubytovani}</i>
                                </b>}
                                {!isAccomodated(label) && <b>
                                  <i>bez ubytování</i>
                                </b>}
                              </td>
                              <td>
                                {label.ubytovaniSt === 0
                                  ? ""
                                  : label.ubytovaniSt}
                              </td>
                              <td>
                                {label.ubytovaniCt === 0
                                  ? ""
                                  : label.ubytovaniCt}
                              </td>
                              <td>
                                {label.ubytovaniPa === 0
                                  ? ""
                                  : label.ubytovaniPa}
                              </td>
                              <td>
                                {label.ubytovaniSo === 0
                                  ? ""
                                  : label.ubytovaniSo}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <table border={0} width="440px" className="label-table">
                          <tbody>
                          <tr>
                            <td>
                              Parkování celokonferenční: {min(
                                  label.parkovaniCt,
                                  label.parkovaniPa,
                                  label.parkovaniSo,
                                  label.parkovaniNe
                                ) < 1
                                  ? ""
                                  : min(
                                      label.parkovaniCt,
                                      label.parkovaniPa,
                                      label.parkovaniSo,
                                      label.parkovaniNe
                                    )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <table
                          border={0}
                          width="440px"
                          className="label-table "
                        >
                          <tbody>
                            <tr>
                              <td>Celokonf. vstup (plný)</td>
                              <td colSpan={2}>{label.poplatekPlny}</td>
                              <td rowSpan={2}>
                                <div style={{ fontSize: "20px" }}>
                                  V
                                  {Number(label.poplatekPlny) +
                                    Number(label.poplatekSnizeny)}
                                </div>
                              </td>
                              <td rowSpan={2}>
                                <div style={{ fontSize: "20px" }}>
                                  K{label.deti15}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Celokonf. vstup (snížený)</td>
                              <td colSpan={2}>{label.poplatekSnizeny}</td>
                            </tr>
                            <tr>
                              <td>Dětský vstup:</td>
                              <td colSpan={2}>{label.deti15}</td>
                              <td colSpan={2}/>
                            </tr>
                            
                            <tr>
                              <td></td>
                              <td width="45">ČT</td>
                              <td width="45">PÁ</td>
                              <td width="45">SO</td>
                              <td width="45">NE</td>
                            </tr>
                            <tr>
                              <td>1-denní vstup:</td>
                              {renderDays("poplatek1DenPlny", "poplatek1DenSnizeny")}
                            </tr>
                            {/*<tr>
                              <td>1-denní vstup (snížený):</td>
                              {renderDays("poplatek1DenSnizeny")}
                            </tr>*/}
                            <tr>
                              <td>Parkování jednodenní:</td>
                              {renderDays("parkovani")}
                            </tr>
                            <tr>
                              <td>Snídaně:</td>
                              {renderDays("snidane")}
                            </tr>
                            <tr>
                              <td>Oběd:</td>
                              {renderDays("obed")}
                            </tr>
                            <tr>
                              <td>Oběd bageta:</td>
                              {renderDays("obedBageta")}
                            </tr>
                            <tr>
                              <td>Večeře:</td>
                              {renderDays("vecereTepla")}
                            </tr>
                            <tr>
                              <td>Večeře bageta:</td>
                              {renderDays("vecereBageta")}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <b>
                          <i>Celkem k úhradě: {label.cenaCelkem} Kč</i>
                        </b>
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "1rem" }}>
                        <b>
                          <i>Zaplaceno: {label.zaplacenaCastka} Kč</i>
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <br />
                        Plastové visačky a šňůrky na krk nám po konferenci
                        můžete vrátit na registraci.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <br />
                        <img
                          src={qrcode}
                          alt="qr-code"
                          style={{ width: "160px" }}
                        />
                      </td>
                      <td valign="top" align="left">
                        <br />
                        <br />
                        Naskenujte kód pro kompletní informace o programu
                        konference. (konferencecharis.cz)
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="ctl00_ContentPlaceHolder1_rptSeznam_ctl00_freeLines">
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Label;
