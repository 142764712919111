import { Grid, TextField } from "@mui/material";
import "./subform.css";
import "./conferencePrice.css";
import { Prices } from "./formContainer";
import { useEffect } from "react";
import {
  nonNegativeIntegerLimited,
  selectOnFocus,
} from "../../helpers/NumericFieldHelper";

const MIN_FOOD = 0;
const MAX_FOOD = 100;

const Food = ({
  sum,
  setSum,
  prices,
  form,
  setForm,
}: {
  sum: number;
  setSum: React.SetStateAction<any>;
  prices: Prices;
  form: any;
  setForm: React.SetStateAction<any>;
}) => {
  const calculateSum = () => {
    setSum(
      form.thursdayBreakfast * prices.breakfast +
        form.fridayBreakfast * prices.breakfast +
        form.saturdayBreakfast * prices.breakfast +
        form.sundayBreakfast * prices.breakfast +
        form.thursdayLunch * prices.lunch +
        form.fridayLunch * prices.lunch +
        form.saturdayLunch * prices.lunch +
        form.sundayLunch * prices.lunch +
        form.thursdayLunchBagette * prices.bagette +
        form.fridayLunchBagette * prices.bagette +
        form.saturdayLunchBagette * prices.bagette +
        form.sundayLunchBagette * prices.bagette +
        form.thursdayDinner * prices.dinner +
        form.fridayDinner * prices.dinner +
        form.saturdayDinner * prices.dinner +
        form.thursdayDinnerBagette * prices.bagette +
        form.fridayDinnerBagette * prices.bagette +
        form.saturdayDinnerBagette * prices.bagette
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    calculateSum();
  }, [form]);

  const validateNumberInput = (e: any, min: number, max: number) => {
    if (e.target.value === undefined || e.target.value === "") {
      setForm({
        ...form,
        [e.target.name]: 0,
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: nonNegativeIntegerLimited(e.target.value, min, max),
      });
    }
  };

  return (
    <div className="subform">
      <h2 className="subform-header">Strava</h2>

      <div className="foodFormContainer">
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <p>Počet porcí</p>
            </Grid>
            <Grid item xs={2}>
              <p>Čt</p>
            </Grid>
            <Grid item xs={2}>
              <p>Pá</p>
            </Grid>
            <Grid item xs={2}>
              <p>So</p>
            </Grid>
            <Grid item xs={2}>
              <p>Ne</p>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4} mt={0.5}>
              <span>Snídaně ({prices.breakfast}Kč)</span>
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="thursdayBreakfast"
                value={form.thursdayBreakfast}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="fridayBreakfast"
                value={form.fridayBreakfast}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="saturdayBreakfast"
                value={form.saturdayBreakfast}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="sundayBreakfast"
                value={form.sundayBreakfast}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4} mt={0.5}>
              <span>Oběd ({prices.lunch}Kč)</span>
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="thursdayLunch"
                value={form.thursdayLunch}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="fridayLunch"
                value={form.fridayLunch}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="saturdayLunch"
                value={form.saturdayLunch}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="sundayLunch"
                value={form.sundayLunch}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4} mt={0.5}>
              <span>Oběd - bageta ({prices.bagette}Kč)</span>
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="thursdayLunchBagette"
                value={form.thursdayLunchBagette}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="fridayLunchBagette"
                value={form.fridayLunchBagette}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="saturdayLunchBagette"
                value={form.saturdayLunchBagette}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="sundayLunchBagette"
                value={form.sundayLunchBagette}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4} mt={0.5}>
              <span>Večeře ({prices.dinner}Kč)</span>
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="thursdayDinner"
                value={form.thursdayDinner}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="fridayDinner"
                value={form.fridayDinner}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="saturdayDinner"
                value={form.saturdayDinner}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4} mt={0.5}>
              <span>Večeře - bageta ({prices.bagette}Kč)</span>
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="thursdayDinnerBagette"
                value={form.thursdayDinnerBagette}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="fridayDinnerBagette"
                value={form.fridayDinnerBagette}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                onBlur={(e) => {
                  validateNumberInput(e, MIN_FOOD, MAX_FOOD);
                }}
                onFocus={selectOnFocus}
                label="Počet"
                name="saturdayDinnerBagette"
                value={form.saturdayDinnerBagette}
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={8} mt={0.5}>
              <span style={{ textAlign: "right" }}> Celkem za stravu </span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="outlined-number"
                label={`Celkem Kč`}
                type="number"
                name="sum"
                value={sum}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Food;
