import AdministrationHeader from "../administrationHeader";
import "../administration.css";

const ReportOverview = () => {
  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="report-content administration-content">
        <h2>Reporty</h2>

        <iframe title="Charis" 
          allowFullScreen 
          src="https://app.powerbi.com/view?r=eyJrIjoiMTNhZTIzYmUtNDM3Zi00YTdiLWI1NGQtYWI4NWMzYTAyMmQxIiwidCI6ImZlOWMwOWJmLWRhYWMtNGI3Yy04NjNjLWM1NDc0ZGM2YTYwNiIsImMiOjh9" 
          style={{border: "none", width: "100%", height: "75vh"}}>          
        </iframe>
      </div>
    </div>
  );
};

export default ReportOverview;
