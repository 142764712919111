import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ChildReport } from "../../../types/ReportTypes";
import { useSnackbar } from "notistack";

const ROW_HEIGHT = 32;
const columns: GridColDef[] = [
  {
    field: "vek",
    headerName: "Věk",
    width: 70,
    valueGetter: (value) => `${value || "0"} let`,
  },
  {
    field: "dk",
    headerName: "Dětská konference",
    type: "number",
    width: 150,
  },
  {
    field: "pocet",
    headerName: "Celkový počet",
    type: "number",
    width: 120,
  },
];

const ChildTable = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<ChildReport[]>([]);

  useEffect(() => {
    setIsPending(true);
    fetch("/api/Child/getReport", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data: ChildReport[]) => {
        // Generate unique ids for each row
        const newData = data.map((row: ChildReport, index: number) => ({
          ...row,
          id: index,
        }));
        setData(newData);
        setIsPending(false);
        console.log(data);
      })
      .catch((err) => {
        setError(true);
        setIsPending(false);
        enqueueSnackbar("Chyba při načítání dat", { variant: "error" });
        console.log(err.message);
      });
  }, []);

  return (
    <div className="table-container">
      {(isPending || data === null) && <div>Načítání...</div>}
      {error && <div>Chyba při načítání dat</div>}
      {!isPending && data !== null && !error && (
        <DataGrid
          rows={data}
          columns={columns}
          rowHeight={ROW_HEIGHT}
          pageSizeOptions={[100]}
        />
      )}
    </div>
  );
};

export default ChildTable;
