import AccomodationTable from "./accomodationTable";
import AccomodationReportTable from "./accomodationReportTable";
import AdministrationHeader from "../administrationHeader";
import "../administration.css";

const AccomodationOverview = () => {
  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="accomodation-content administration-content table">
        <h2>Přehled ubytování</h2>

        <AccomodationTable />
      </div>

      <div className="administration-content table">
        <h2>Report ubytování</h2>
        <AccomodationReportTable />
      </div>
    </div>
  );
};

export default AccomodationOverview;
