import AdministrationHeader from "../administrationHeader";
import "../administration.css";
import ChildTable from "./childTable";

const ChildOverview = () => {
  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="child-content administration-content table">
        <h2>Přehled počtu dětí</h2>

        <ChildTable />
      </div>
    </div>
  );
};

export default ChildOverview;
