import { useEffect, useState } from "react";
import "./usersContainer.css";
import Administration from "../../../types/AdministrationTypes";
import UsersTable from "./usersTable";
import { Button } from "@mui/material";
import AddUserModal from "./modals/AddUserModal";
import DeleteUserModal from "./modals/deleteUsermodal";
import EditPasswordModal from "./modals/editPasswordModal";
import { useSnackbar } from "notistack";
import AdministrationHeader from "../administrationHeader";
import ChangeRoleModal from "./modals/changeRoleModal";

const UsersContainer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState<Administration.User[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  const [deleteUserId, setDeleteUserId] = useState(-1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [editPasswordUserId, setEditPasswordUserId] = useState(-1);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);

  const [changeRoleId, setChangeRoleId] = useState(-1);
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  /* On refresh, pull user datas. */
  useEffect(() => {
    updateUsers();
  }, []);

  /* GET request for user datas. */
  const updateUsers = () => {
    setIsPending(true);
    fetch(`/api/User/users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setUsers(data);
        setIsPending(false);
      })
      .catch((error) => {
        setIsPending(false);
        setError(true);
      });
  };

  /* Opens dialogue to delete user, sets ID of enqueued user (which will be deleted) */
  const toggleDeleteUserModal = (id: number) => {
    setDeleteUserId(id);
    setShowDeleteModal(true);
  };

  /* Called when delete user dialogue is submitted. DELETE request for user by enqueued user ID. */
  const deleteUserAction = (success: boolean) => {
    setShowDeleteModal(false);
    if (success && deleteUserId !== -1) {
      fetch(`/api/User/users/${deleteUserId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json",
        },
      })
        .then(() => {
          setDeleteUserId(-1);
          enqueueSnackbar("Uživatel byl smazán.", {
            variant: "success",
          });
          updateUsers();
        })
        .catch((error) => {
          enqueueSnackbar("Něco se pokazilo: " + error, { variant: "error" });
        });
    } else {
      setDeleteUserId(-1);
    }
  };

  /* Opens edit password dialogue, sets enqueued user ID (to update password) */
  // TODO: password submit has delay on entry fields
  const toggleEditPasswordModal = (id: number) => {
    setEditPasswordUserId(id);
    setShowEditPasswordModal(true);
  };

  const toggleChangeRoleModal = (id: number) => {
    setChangeRoleId(id);
    setShowChangeRoleModal(true);
  };

  /* Called on submit of edit password dialogue, checks password format and makes PUT request to update password by enqueued user ID. */
  const editPasswordAction = (success: boolean, data: any) => {
    setShowEditPasswordModal(false);

    if (success && editPasswordUserId !== -1) {
      fetch(`/api/User/users/${editPasswordUserId}/password`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(() => {
          setEditPasswordUserId(-1);
          enqueueSnackbar("Heslo bylo změněno", { variant: "success" });
        })
        .catch((error) => {
          enqueueSnackbar("Něco se pokazilo: " + error, { variant: "error" });
        });
    } else {
      setEditPasswordUserId(-1);
    }
  };

  /* Opens add new user dialogue */
  const toggleAddUserModal = () => {
    setShowAddUserModal(true);
  };

  /* Called when new user dialogue is submitted, performs POST to add new user (role is set default - basic user) */
  const addUserAction = (success: boolean, data: any) => {
    setShowAddUserModal(false);

    if (success) {
      fetch(`/api/User/users`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(() => {
          enqueueSnackbar("Uživatel byl přidan", { variant: "success" });
          updateUsers();
        })
        .catch((error) => {
          enqueueSnackbar("Něco se pokazilo: " + error, { variant: "error" });
        });
    }
  };

  /* Called when table cells are modified, performs PUT request to update user by datas from table row. */
  const editUserAction = (userId: number, data: any) => {
    const dataJson = {
      username: data.username,
      name: data.name,
      surname: data.surname,
      role: data.role === "Admin" ? 1 : 2,
    };

    fetch(`/api/User/users/${userId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    })
      .then(() => {
        enqueueSnackbar("Uživatel byl změněn", { variant: "success" });
        updateUsers();
      })
      .catch((error) => {
        enqueueSnackbar("Něco se pokazilo: " + error, { variant: "error" });
      });
  };

  const changeRoleAction = (success: boolean, data: any) => {
    setShowChangeRoleModal(false);

    if (success && changeRoleId !== -1 && data !== null) {
      fetch(`/api/User/users/${changeRoleId}/role`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(() => {
          setChangeRoleId(-1);
          enqueueSnackbar("Role byla změněna", { variant: "success" });
          updateUsers();
        })
        .catch((error) => {
          enqueueSnackbar("Něco se pokazilo: " + error, { variant: "error" });
        });
    }
  };

  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="accomodation-content administration-content table">
        <h2>Administrace uživatelů</h2>

        {isPending && <h1>Načítání...</h1>}
        {users && (
          <div>
            <Button
              variant="contained"
              onClick={toggleAddUserModal}
              style={{ marginBottom: "1rem" }}
            >
              Přidat uživatele
            </Button>
            <UsersTable
              users={users}
              deleteAction={toggleDeleteUserModal}
              editPasswordAction={toggleEditPasswordModal}
              changeRoleAction={toggleChangeRoleModal}
              mutation={editUserAction}
            />
          </div>
        )}
        {error && <h1>error</h1>}

        {showAddUserModal && (
          <AddUserModal open={showAddUserModal} close={addUserAction} />
        )}
        {showDeleteModal && (
          <DeleteUserModal
            open={showDeleteModal}
            close={deleteUserAction}
            data={"Jste si jistý?"}
          />
        )}
        {showEditPasswordModal && (
          <EditPasswordModal
            open={showEditPasswordModal}
            close={editPasswordAction}
          />
        )}
        {showChangeRoleModal && (
          <ChangeRoleModal
            open={showChangeRoleModal}
            close={changeRoleAction}
          />
        )}
      </div>
    </div>
  );
};

export default UsersContainer;
