import AdministrationHeader from "../administration/administrationHeader";
import Footer from "../common/footer";
import FormContainer from "../form/formContainer";

const AdminRegistrationPage = () => {
    return ( 
        <>
            <AdministrationHeader />
            <FormContainer edit={false} signedIn={true}/>
        </>
     );
}
 
export default AdminRegistrationPage;