import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "notistack";

const EditPasswordModal = (props: any) => {

    const { enqueueSnackbar } = useSnackbar();

    const [newPassword, setNewPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');

    const [passwordError, setPasswordError] = useState(true);

    const handleClose = (success: boolean, data: any = null) => {
        props.close(success === true, data);
    };

    const validatePasswordFormat = (arg: string) => {
        const passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(arg);
    }

    const onSuccess = (event: React.FormEvent<HTMLFormElement>) => {
        if (passwordError) {
            return;
        }

        if (!validatePasswordFormat(newPassword)) {
            enqueueSnackbar("Heslo musí obsahovat minimálně 8 znaků, malá a velká písmena a čislovky. Příklad: 'Test12345'", { variant: 'error' });
            return;
        }
        const formJson = {
            newPassword: newPassword
        };
        handleClose(true, formJson);
    }

    const handleNewPassword = (event: any) => {
        setNewPassword(event.target.value);
        setPasswordError(event.target.value !== passwordAgain)
    }

    const handlePasswordAgain = (event: any) => {
        setPasswordAgain(event.target.value);
        setPasswordError(event.target.value !== newPassword)
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    onSuccess(event);
                },
            }}
        >
            <DialogTitle>Změna hesla pro uživatele</DialogTitle>
            <DialogContent className='dialog-content-fields-container'>
                <TextField
                    autoFocus
                    required
                    id="password"
                    name="password"
                    label="Heslo"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleNewPassword}
                />
                <TextField
                    id="passwordAgain"
                    name="passwordAgain"
                    label="Heslo znovu"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handlePasswordAgain}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { handleClose(false) }} variant="contained" >Zrušit</Button>
                <Button type="submit" variant="contained" disabled={passwordError}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditPasswordModal;