import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const DeleteUserModal = (props: any) => {
    const handleClose = (success: boolean) => {
        props.close(success);
    }

    const onSuccess = (event: React.FormEvent<HTMLFormElement>) => {
        handleClose(true);
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    onSuccess(event);
                },
            }}
        >
            <DialogTitle>{props.data}</DialogTitle>
            <DialogActions>
                <Button onClick={() => { handleClose(false) }} variant="contained">Ne</Button>
                <Button type="submit" variant="contained">Ano</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteUserModal;