import AdministrationHeader from "../administrationHeader";
import "../administration.css";
import PresentationTable from "./presentationTable";

const PresentationOverview = () => {
  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="child-content administration-content table">
        <h2>Přehled počtu přihlášených na prezentace</h2>

        <PresentationTable />
      </div>
    </div>
  );
};

export default PresentationOverview;
