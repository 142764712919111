import { Button } from "@mui/material";
import { useState } from "react";
import AdministrationHeader from "../administrationHeader";
import { useSnackbar } from "notistack";

const ImportBankStatement = () => {

  const [file, setFile] = useState<any>(null);    //define list instead
  const [uploading, setUploading] = useState<boolean>(false);    //define list instead

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0])      //append the file list
  };

  const { enqueueSnackbar } = useSnackbar();

  const uploadFile = () => {
    setUploading(true);
    const formData = new FormData();      //make a bew FormData for every file.
    formData.append("file", file, file.name);

    fetch("/api/Payment/importBankStatement/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      body: formData,
    })
      .then((res) => {
        console.log(res);
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource!");
        }
        setUploading(false);
        enqueueSnackbar('Platby byly úspěšně nahrané.', { variant: 'success' })
      })
      .catch((err) => {
        setUploading(false);
        enqueueSnackbar('Došlo k chybě při nahrávání plateb.', { variant: 'error' })
        console.log(err);
        console.log(err.message);
      });
  }

  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="accomodation-content administration-content table">
        <h2>Import plateb</h2>

        <div>
          <label style={{ paddingRight: '10px' }}>Výpis z banky ve formátu csv</label>
          <input type="file" accept=".csv, application/vnd.ms-excel" onChange={handleFileChange} />
        </div>
        <div>
          <Button type="button" onClick={uploadFile} variant="contained" disabled={file == null} style={{ margin: '15px' }}>Nahrát</Button>
        </div>
        {uploading && <div>Nahrávám...</div>}
      </div>
    </div>
  );
};

export default ImportBankStatement;
