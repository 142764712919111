import { useEffect, useState } from "react";
import Section from "../common/section";
import "./checkTable.css";
import { Grid } from "@mui/material";
import { ChildUnder15 } from "../form/formContainer";

const CheckTable = ({
  basicInformation,
  conferencePriceCount,
  sumConferencePrice,
  childrenUnder15,
  accomodationInformation,
  accomodations,
  foodInformation,
  sumTotalPrice,
  contributionChildrenUnder15,
  contribution,
  exchangeRate,
}: {
  basicInformation: any;
  conferencePriceCount: any;
  sumConferencePrice: number;
  childrenUnder15: ChildUnder15[];
  accomodationInformation: any;
  accomodations: any;
  foodInformation: any;
  sumTotalPrice: number;
  contributionChildrenUnder15: number;
  contribution: number;
  exchangeRate: number;
}) => {
  const [orderAccomodation, setOrderAccomodation] = useState<boolean>(true);

  useEffect(() => {
    setOrderAccomodation(
      accomodationInformation.thursday > 0 ||
        accomodationInformation.friday > 0 ||
        accomodationInformation.saturday > 0 ||
        accomodationInformation.sunday > 0
    );
  }, []);

  return (
    <Section>
      <div className="check-table">
        <h2 className="check-table-header">Přihláška</h2>
        <p className="check-table-note">
          Zkontrolujte prosím uvedené údaje a poté odešlete přihlášku ke
          zpracování tlačítkem odeslat:
        </p>
        <div className="check-table-content">
          <Grid container spacing={0.5} className="subtable">
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <p>
                  Jméno:{" "}
                  <b>
                    {basicInformation.firstName} {basicInformation.lastName}
                  </b>
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  Telefon: <b>{basicInformation.telefon}</b>
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  Email: <b>{basicInformation.email}</b>
                </p>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <p>
                  Adresa:{" "}
                  <b>
                    {basicInformation.street}, {basicInformation.zip}{" "}
                    {basicInformation.city},{" "}
                    {basicInformation.state === 0 ? "CZE" : "SK"}
                  </b>
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={0.5} className="subtable">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <p>
                  Celokonferenční vstupy:{" "}
                  <b>
                    {conferencePriceCount.multiday} ks Plný,{" "}
                    {conferencePriceCount.multidayReduced} ks Snížený
                  </b>
                </p>
              </Grid>
            </Grid>
            <Grid container item xs={12}></Grid>
            <Grid container item xs={12}>
              <Grid item xs={4}></Grid>
              <Grid item xs={1}>
                <p>Čt</p>
              </Grid>
              <Grid item xs={1}>
                <p>Pá</p>
              </Grid>
              <Grid item xs={1}>
                <p>So</p>
              </Grid>
              <Grid item xs={1}>
                <p>Ne</p>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <p>Jednodenní vstupy - plné:</p>
              </Grid>
              <Grid item xs={1}>
                <p>{conferencePriceCount.thursday}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{conferencePriceCount.friday}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{conferencePriceCount.saturday}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{conferencePriceCount.sunday}</p>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <p>Jednodenní vstupy - snížené:</p>
              </Grid>
              <Grid item xs={1}>
                <p>{conferencePriceCount.thursdayReduced}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{conferencePriceCount.fridayReduced}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{conferencePriceCount.saturdayReduced}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{conferencePriceCount.sundayReduced}</p>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <p>
                  Dětský vstup (do 15-ti let): <b>{childrenUnder15.length}</b>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>
                  Z toho Dětská konference:{" "}
                  <b>
                    {childrenUnder15.filter((child) => child.conference).length}
                  </b>
                </p>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <p>
                  Konferenční poplatky celkem: <b>{sumConferencePrice} Kč</b>
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={0.5} className="subtable">
            <Grid container item xs={12}>
              <Grid item xs={3}></Grid>
              <Grid item xs={1}>
                <p>Čt</p>
              </Grid>
              <Grid item xs={1}>
                <p>Pá</p>
              </Grid>
              <Grid item xs={1}>
                <p>So</p>
              </Grid>
              <Grid item xs={1}>
                <p>Ne</p>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={3}>
                <p>Ubytování:</p>
              </Grid>
              <Grid item xs={1}>
                <p>{accomodationInformation.thursday}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{accomodationInformation.friday}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{accomodationInformation.saturday}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{accomodationInformation.sunday}</p>
              </Grid>
              <Grid item xs={5}>
                {orderAccomodation && (
                  <p>
                    Místo:{" "}
                    <b>
                      {
                        accomodations[accomodationInformation.accomodation]
                          .nazev
                      }
                    </b>
                  </p>
                )}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={3}>
                <p>Parkování:</p>
              </Grid>
              <Grid item xs={1}>
                <p>{accomodationInformation.thursdayParking}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{accomodationInformation.fridayParking}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{accomodationInformation.saturdayParking}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{accomodationInformation.sundayParking}</p>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>

            {orderAccomodation && (
              <Grid container item xs={12}>
                <Grid item xs={3}>
                  <p>Seznam ubytovaných:</p>
                </Grid>

                <Grid item xs={9}>
                  {accomodationInformation.persons.map(
                    (person: any, index: any) => (
                      <p className="list" key={index}>
                        <b>
                          {person.firstName} {person.lastName}, {person.street},{" "}
                          {person.zip} {person.city}
                          <br /> {person.nights} nocí;{" "}
                          {person.eighteen ? "platí" : "neplatí"} poplatek městu
                        </b>
                      </p>
                    )
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={0.5} className="subtable">
            <Grid container item xs={12}>
              <Grid item xs={3}></Grid>
              <Grid item xs={1}>
                <p>Čt</p>
              </Grid>
              <Grid item xs={1}>
                <p>Pá</p>
              </Grid>
              <Grid item xs={1}>
                <p>So</p>
              </Grid>
              <Grid item xs={1}>
                <p>Ne</p>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <p>Snídaně:</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.thursdayBreakfast}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.fridayBreakfast}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.saturdayBreakfast}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.sundayBreakfast}</p>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <p>Oběd:</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.thursdayLunch}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.fridayLunch}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.saturdayLunch}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.sundayLunch}</p>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <p>Oběd - bageta:</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.thursdayLunchBagette}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.fridayLunchBagette}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.saturdayLunchBagette}</p>
              </Grid>
              <Grid item xs={1}>
                <p>{foodInformation.sundayLunchBagette}</p>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <p>Večeře:</p>
            </Grid>
            <Grid item xs={1}>
              <p>{foodInformation.thursdayDinner}</p>
            </Grid>
            <Grid item xs={1}>
              <p>{foodInformation.fridayDinner}</p>
            </Grid>
            <Grid item xs={1}>
              <p>{foodInformation.saturdayDinner}</p>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}></Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={3}>
              <p>Večeře - bageta:</p>
            </Grid>
            <Grid item xs={1}>
              <p>{foodInformation.thursdayDinnerBagette}</p>
            </Grid>
            <Grid item xs={1}>
              <p>{foodInformation.fridayDinnerBagette}</p>
            </Grid>
            <Grid item xs={1}>
              <p>{foodInformation.saturdayDinnerBagette}</p>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}></Grid>
          </Grid>

          <Grid container spacing={0.5} className="subtable">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <p>
                  Dobrovolný příspěvek: <b>{contribution} Kč</b>
                </p>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <p>
                  Dobrovolný příspěvek na dětskou konferenci:{" "}
                  <b>{contributionChildrenUnder15} Kč</b>
                </p>
              </Grid>
            </Grid>
            <Grid container item xs={12}></Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <p>
                  <b>
                    Celkem k úhradě:{" "}
                    {basicInformation.state === 0
                      ? sumTotalPrice + " Kč"
                      : (sumTotalPrice / exchangeRate).toFixed(2) + " EUR"}
                  </b>{" "}
                  (Pro platbu v {basicInformation.state === 0 ? "EUR" : "Kč"}{" "}
                  zvolte prosím v přihlášce jinou zemi)
                </p>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <p>
                  <b>
                    Odesláním elektronické přihlášky bereme požadované ubytování
                    a stravování za závazně objednané. Prosíme o zaplacení do 7
                    dní po přihlášení. Organizátor konference není (stejně jako
                    minulé roky) poskytovatelem ubytování a stravování, pouze
                    tyto služby pomáhá účastníkům zajistit.
                  </b>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Section>
  );
};
export default CheckTable;
