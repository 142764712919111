import { Button } from "@mui/material";
import Section from "../common/section";
import { useSnackbar } from "notistack";
import { Roles } from "../../helpers/RolesEnum";
import NoteBoard from "./noteboard/noteboard";

const AdminLandingPageContent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const roleString = localStorage.getItem("userRole");
  const userRole = roleString !== null ? parseInt(roleString) : 2;

  const logout = () => {
    localStorage.clear();
    enqueueSnackbar("Uživatel odhlášen", { variant: "success" });
    setTimeout(function () {
      window.location.href = "/login";
    }, 1000);
  };

  return (
    <div className="administration-content administartion-landing-page--content">
      <div className="administration-content--links">
        <Section>
          <h2>Zadávání:</h2>
          <div className="administarion-content--list">
            <a href="/admin/pridatplatbu">Přidat platbu</a>
          </div>
        </Section>
        <Section>
          <h2>Přihláška:</h2>
          <div className="administarion-content--list">
            {userRole === Roles.Admin &&
              <a href="/admin/registrace">Nová přihláška s VS</a>
            }
            <a href="/admin/registracebezvs">Nová přihláška bez VS</a>
            <a href="/admin/prodej">Prodej na místě</a>
          </div>
        </Section>
        <Section>
          <h2>Přehled:</h2>
          <div className="administarion-content--list">
            <a href="/admin/prihlasky">Přihlášky</a>
            <a href="/admin/nezaplaceneprihlasky">Nezaplacené přihlášky</a>
            <a href="/admin/deti">Děti</a>
            <a href="/admin/prednasky">Přednášky</a>
            <a href="/admin/ubytovani">Ubytování</a>
            <a href="/admin/stravovani">Stravování</a>
            {userRole === Roles.Admin && (
              <>
                <a href="/admin/importplateb">Import plateb</a>
                <a href="/admin/nepotvrzeneplatby">Nepotvrzené platby</a>
                <a href="/admin/users">Uživatelé</a>
                <a href="/admin/reports">Reporty</a>
              </>
            )}
          </div>
        </Section>
        {userRole === Roles.Admin && <Section>
          <h2></h2>

          <div className="administarion-content--list">
            <a href="/admin/notifikace">E-mailová notifikace</a>
          </div>
        </Section>}
        <Section>
          <Button variant="contained" style={{ marginTop: "20px" }} onClick={logout}>
            Odhlásit se
          </Button>
        </Section>
      </div>
      <div className="administration-content--noteboard">
        <Section>
          <div>
            <h2>Nástěnka:</h2>
            <NoteBoard />
          </div>
        </Section>
      </div>
    </div>
  );
};

export default AdminLandingPageContent;
