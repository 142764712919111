import RegistrationTable from "./registrationTable";
import AdministrationHeader from "../administrationHeader";
import "../administration.css";

const RegistrationOverview = () => {
  return (
    <div className="administration">
      <AdministrationHeader />
      <div className="registration-content administration-content table">
        <h2>Přehled přihlášek</h2>

        <RegistrationTable />
      </div>
    </div>
  );
};

export default RegistrationOverview;
