export const selectOnFocus = (event: any) => event.target.select();

export const nonNegativeIntegerLimited = (value: string, min: number, max: number, negative: boolean = false) => {
    let number = nonNegativeInteger(value, negative);

    if (number < min) {
        number = min;
    } else if (number > max) {
        number = max;
    }
    return Number.isNaN(number) ? min : number;
}

export const nonNegativeInteger = (value: string, negative: boolean = false) => {
  return !negative ? Math.abs(parseInt(value)) : parseInt(value);
}