import { Button, Checkbox, Grid, MenuItem, TextField } from "@mui/material";
import "./subform.css";
import { useEffect, useState } from "react";
import {
  nonNegativeIntegerLimited,
  selectOnFocus,
} from "../../helpers/NumericFieldHelper";
import { currencies } from "../../helpers/CurrencyEnum";
import { useSnackbar } from "notistack";

const TotalPrice = ({
  editMode,
  sum,
  setSum,
  contribution,
  setContribution,
  exchangeRate,
  conference,
  accomodation,
  food,
  childContribution,
  state,
  payedAmount,
  toPay,
  variableSymbol,
  update,
  onSite,
  setPayment
}: {
  editMode: boolean;
  sum: number;
  setSum: React.SetStateAction<any>;
  contribution: number;
  setContribution: React.SetStateAction<any>;
  exchangeRate: number;
  conference: number;
  accomodation: {
    accomodation: number;
    parking: number;
    cityTax: number;
  };
  food: number;
  childContribution: number;
  state: number;
  payedAmount?: number;
  toPay?: number;
  variableSymbol?: string;
  update?: (isPdf: boolean) => void;
  onSite?: boolean;
  setPayment?: React.SetStateAction<any>;
}) => {
  const [payAmount, setPayAmount] = useState(0);
  const [changeAmount, setChangeAmount] = useState(0);

  const [isPending, setIsPending] = useState(false);
  const [currency, setCurrency] = useState(0);
  const [changeCurrency, setChangeCurrency] = useState(0);

  const [type, setType] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const calculateSum = () => {
    setSum(
      parseInt(accomodation.accomodation as any) +
      parseInt(accomodation.parking as any) +
      parseInt(accomodation.cityTax as any) +
      parseInt(conference as any) +
      parseInt(food as any) +
      parseInt(contribution as any) +
      parseInt(childContribution as any)
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContribution(nonNegativeIntegerLimited(e.target.value, 0, 100000));
  };

  useEffect(() => {
    calculateSum();
  }, [contribution, accomodation, conference, food, childContribution]);

  useEffect(() => {
    if(setPayment == null){
      return;
    }
    const payed = currency === 0 ? 
    
    payAmount - getUnifiedValue(changeCurrency, changeAmount) :

    payAmount - (changeCurrency === 0 ? (Math.round((
      changeAmount / exchangeRate + Number.EPSILON) * 100) /
    100) : changeAmount);  

    setPayment({castka: getUnifiedValue(currency, payed),
      mena: currency === 0 ? "CZK" : "EUR",
      typ: Number(type)});  
  }, [payAmount, changeAmount, currency, changeCurrency])
    

  const getUnifiedValue = (curr: number, amount: number) => {
    return (curr === 0
      ? amount
      : amount * exchangeRate);
  }

  const getUnifiedTotalChange = () => {
    const payed = getUnifiedValue(currency, payAmount) - getUnifiedValue(changeCurrency, changeAmount);
    const deducted = sum - (payedAmount ?? 0) - payed;

    return deducted < 0 ? deducted * -1 : 0;
  }

  return (
    <div className="subform">
      <h2 className="subform-header">Součet</h2>

      <div className="foodFormContainer">
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3} style={{marginTop: 2}}>
            <Grid item xs={8} mt={0.5}>
              <span style={{ textAlign: "right" }}>
                {" "}
                Děkujeme též za Váš laskavý dobrovolný příspěvek{" "}
              </span>
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-number"
                label={`Příspěvek Kč`}
                type="number"
                onBlur={(e) => {
                  setContribution(
                    nonNegativeIntegerLimited(e.target.value, 0, 100000)
                  );
                }}
                onFocus={selectOnFocus}
                name="contribution"
                value={contribution}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          {/*state === 0 && */(
            <Grid container item xs={12} spacing={3} style={{marginTop: 5}}>
              <Grid item xs={8} mt={0.5}>
                <span style={{ textAlign: "right" }}> Celkem k úhradě </span>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Celkem Kč`}
                  type="number"
                  name="sum"
                  value={sum}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Celkem EUR`}
                  type="number"
                  name="sum"
                  value={
                    Math.round((sum / exchangeRate + Number.EPSILON) * 100) /
                    100
                  }
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          )}
          {payedAmount !== undefined && (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={8} mt={0.5}>
                <span style={{ textAlign: "right" }}> Zaplaceno</span>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Zaplaceno Kč`}
                  type="number"
                  name="payedAmount"
                  value={Math.ceil(payedAmount)}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Zaplaceno EUR`}
                  type="number"
                  name="payedAmount"
                  value={Math.round((payedAmount / exchangeRate + Number.EPSILON) * 100) /
                    100}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          )}
          {toPay !== undefined && (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={8} mt={0.5}>
                <span style={{ textAlign: "right" }}>Zbývá zaplatit</span>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Zbývá Kč`}
                  type="number"
                  name="toPay"
                  value={Math.ceil(payedAmount === undefined || sum - payedAmount - payAmount < 0 ? 0 : sum - payedAmount - payAmount)}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-number"
                  label={`Zbývá EUR`}
                  type="number"
                  name="toPay"
                  value={Math.round((
                    (payedAmount === undefined || sum - payedAmount - payAmount< 0 ? 0 : sum - payedAmount - payAmount) / exchangeRate + Number.EPSILON) * 100) /
                    100}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          )}
          {editMode && (
            <>
              <Grid
                container
                item
                xs={12}
                spacing={3}
              >
                <Grid item xs={8} mt={0.5}>
                  <span className="payment-label"> Zbývá vrátit </span>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    id="outlined-number"
                    label={`Vrátit Kč`}
                    type="number"
                    name="returnAmount"
                    value={
                      Math.ceil(getUnifiedTotalChange())
                    }
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    id="outlined-number"
                    label={`Vrátit EUR`}
                    type="number"
                    name="returnAmount"
                    value={
                      Math.round((
                        getUnifiedTotalChange() / exchangeRate + Number.EPSILON) * 100) /
                      100
                    }
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={3} style={{marginTop: 10}}>
                <Grid item xs={8} mt={0.5}>
                  <span className="payment-label"> Přijato </span>
                </Grid>
                <Grid item xs={4}>
                  <div className="line">
                    <TextField
                      size="small"
                      id="outlined-number"
                      label={`Zaplacená částka`}
                      type="number"
                      required
                      onBlur={(e) => {
                        setPayAmount(
                          nonNegativeIntegerLimited(e.target.value, 0, 1000000)
                        );
                      }}
                      onFocus={selectOnFocus}
                      name="amount"
                      value={payAmount}
                      onChange={(e) => setPayAmount(e.target.value as any)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      size="small"
                      id="currency"
                      name="currency"
                      select
                      label="Měna"
                      style={{ textAlign: "left", minWidth: "100px" }}
                      value={currency}
                      onChange={(e) => setCurrency(Number(e.target.value))}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={8} mt={0.5}>
                  <span className="payment-label"> Vráceno </span>
                </Grid>
                <Grid item xs={4}>
                  <div className="line">
                    <TextField
                      size="small"
                      id="outlined-number"
                      label={`Zaplacená částka`}
                      type="number"
                      required
                      onBlur={(e) => {
                        setChangeAmount(
                          nonNegativeIntegerLimited(e.target.value, 0, 1000000)
                        );
                      }}
                      onFocus={selectOnFocus}
                      name="amount"
                      value={changeAmount}
                      onChange={(e) => setChangeAmount(e.target.value as any)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      size="small"
                      id="currency"
                      name="currency"
                      select
                      label="Měna"
                      style={{ textAlign: "left", minWidth: "100px" }}
                      value={changeCurrency}
                      onChange={(e) => setChangeCurrency(Number(e.target.value))}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6} mt={0.5}>
                  <span className="payment-label"> Hotovost </span>
                </Grid>
                <Grid item xs={6}>
                  <div className="line">
                    <Checkbox
                      name="type"
                      value={type}
                      checked={type}
                      onChange={(e) => setType(e.target.checked)}
                    />
                    <span>v případě nezatrhnutí jde o bankovní převod</span>
                  </div>
                </Grid>
              </Grid>              
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default TotalPrice;
