import { Navigate } from "react-router-dom";
import AuthProvider from "./components/common/authProvider";

interface PrivateRouteProps {
    guard: any;
    children: any;
}
const PrivateRoute = ({ guard, children }: PrivateRouteProps) => {

  const isLoggedIn = localStorage.getItem('userToken');
  const roleString = localStorage.getItem('userRole')
  const userRole = roleString !== null ? parseInt(roleString) : 2;
  let hasRights = true;

  if (guard && Array.isArray(guard)) {
      hasRights = guard.some((role: any) => userRole && userRole === role);

      if(guard.length === 0){
        hasRights = true;
      }
  }

    if(isLoggedIn){
        if(hasRights){

            // authorized, has rights
            return <AuthProvider>{children}</AuthProvider>
        }

        // authorized, doesn't have rights
        return (<Navigate to="/unauthorized" replace={true} />)
    }
      
        // not authorized
        return (<Navigate to="/login" replace={true} />);
}

export default PrivateRoute