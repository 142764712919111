import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Content from "../common/content";
import BasicInformation from "./basicInformation";
import ConferencePrice from "./conferencePrice";
import ChildrenUnder15 from "./childrenUnder15";
import Accomodation from "./accomodation";
import Food from "./food";
import TotalPrice from "./totalPrice";
import Presentation from "./presentation";
import CheckTable from "../check/checkTable";
import "./formContainer.css";
import RegistrationSended from "./registrationSended";
import ErrorDialog from "../common/errorDialog";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";
import {
  EmailRegex,
  PhoneRegex,
  ZipRegex,
} from "../../helpers/RegexValidateHelper";
import { useSnackbar } from "notistack";
import WarningDialog from "../common/warningDialog";
import CustomVariableSymbol from "./customVariableSymbol";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const SITE_KEY = "6Lfjm7UpAAAAAKFQbwfcdpcnl_EMfIQoigDPDScR";

export interface Prices {
  bagette: number;
  parking: number;
  wholeDay: number;
  wholeMultiday: number;
  wholeAfter: number;
  wholeBefore: number;
  reducedDay: number;
  reducedMultiday: number;
  reducedAfter: number;
  reducedBefore: number;
  breakfast: number;
  lunch: number;
  dinner: number;
}

export interface ChildUnder15 {
  name: string;
  age: number | undefined;
  conference: boolean;
}

export interface PersonAccomodation {
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  zip: string;
  eighteen: boolean;
  nights: number;
}

type FormContainerProps = {
  edit: boolean;
  registrationData?: any;
  registrationId?: number;
  signedIn?: boolean;
  withoutVS?: boolean;
};

const FormContainer = ({
  edit,
  registrationData,
  registrationId,
  signedIn = false,
  withoutVS = false,
}: FormContainerProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let linkUrl = "/api/registration/download/" + registrationData?.varSymbol;
  const headers = new Headers();
  headers.append(
    "Authorization",
    `Bearer ${localStorage.getItem("userToken")}`
  );

  const [date, setDate] = useState(new Date());
  const [exchangeRate, setExchangeRate] = useState(25);
  const [id, setId] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isPending, setIsPending] = useState(true);
  const [isSended, setIsSended] = useState(false);

  const [customVariableSymbol, setCustomVariableSymbol] = useState(90000);
  const [customVariableSymbolError, setCustomVariableSymbolError] = useState(false);

  const [payment, setPayment] = useState({
    castka: 0,
    mena: "",
    typ: 0,
  });

  useEffect(() => {
    if (!edit) {
      const loadScriptByURL = (id: any, url: any, callback: any) => {
        const isScriptExist = document.getElementById(id);

        if (!isScriptExist) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.id = id;
          script.onload = function () {
            if (callback) callback();
          };
          document.body.appendChild(script);
        }

        if (isScriptExist && callback) callback();
      };

      // load the script by passing the URL
      loadScriptByURL(
        "recaptcha-key",
        `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
        function () {
          console.log("Script loaded!");
        }
      );
    }
  }, []);

  useEffect(() => {
    if (!error) {
      scrollToError(false);
    }
  }, [error]);

  const goHome = () => {
    navigate("/admin");
  };

  const [formInformation, setFormInformation] = useState({
    variableSymbol: registrationData?.varSymbol ?? null,
    present: registrationData?.pritomen ?? false,
    deleted: registrationData?.smazano ?? false,
  });

  const [basicInformation, setBasicInformation] = useState({
    firstName: registrationData?.jmeno ?? "",
    lastName: registrationData?.prijmeni ?? "",
    street: registrationData?.ulice ?? "",
    city: registrationData?.mesto ?? "",
    state: registrationData?.zeme ?? 0,
    zip: registrationData?.psc ?? "",
    email: registrationData?.email ?? "",
    telefon: registrationData?.telefon ?? "+420",
  });

  const [conferencePriceCount, setConferencePriceCount] = useState({
    multiday: registrationData?.poplatekPlny ?? 0,
    multidayReduced: registrationData?.poplatekSnizeny ?? 0,
    thursday: registrationData?.poplatek1DenPlnyCt ?? 0,
    thursdayReduced: registrationData?.poplatek1DenSnizenyCt ?? 0,
    friday: registrationData?.poplatek1DenPlnyPa ?? 0,
    fridayReduced: registrationData?.poplatek1DenSnizenyPa ?? 0,
    saturday: registrationData?.poplatek1DenPlnySo ?? 0,
    saturdayReduced: registrationData?.poplatek1DenSnizenySo ?? 0,
    sunday: registrationData?.poplatek1DenPlnyNe ?? 0,
    sundayReduced: registrationData?.poplatek1DenSnizenyNe ?? 0,
  });

  const [accomodations, setAccomodations] = useState([]);
  const [presentations, setPresentations] = useState([]);
  const [prices, setPrices] = useState<Prices>({
    bagette: 0,
    parking: 0,
    wholeDay: 0,
    wholeMultiday: 0,
    wholeAfter: 0,
    wholeBefore: 0,
    reducedDay: 0,
    reducedMultiday: 0,
    reducedAfter: 0,
    reducedBefore: 0,
    breakfast: 0,
    lunch: 0,
    dinner: 0,
  });

  const [sumConferencePrice, setSumConferencePrice] = useState<number>(0);
  const [sumFoodPrice, setSumFoodPrice] = useState<number>(0);
  const [sumTotalPrice, setSumTotalPrice] = useState<number>(0);

  const [contribution, setContribution] = useState<number>(
    registrationData?.dobrovolnyPrispevek ?? 0
  );

  const [listeners, setListeners] = useState<number[]>([]);

  const [childrenUnder15, setChildrenUnder15] = useState<ChildUnder15[]>(
    registrationData?.deti ?? []
  );
  const [contributionChildrenUnder15, setContributionChildrenUnder15] =
    useState<number>(registrationData?.dobrovolnyPrispevekDeti ?? 0);

  const [accomodationSum, setAccomodationSum] = useState({
    accomodation: 0,
    parking: 0,
    cityTax: 0,
  });

  const [accomodationInformation, setAccomodationInformation] = useState({
    // need index, not id, is set after accomodations are fetched
    accomodation: 0,

    persons: registrationData?.ubytovaniOsoby ?? [],
    thursday: registrationData?.ubytovaniSt ?? 0,
    friday: registrationData?.ubytovaniCt ?? 0,
    saturday: registrationData?.ubytovaniPa ?? 0,
    sunday: registrationData?.ubytovaniSo ?? 0,
    thursdayParking: registrationData?.parkovaniCt ?? 0,
    fridayParking: registrationData?.parkovaniPa ?? 0,
    saturdayParking: registrationData?.parkovaniSo ?? 0,
    sundayParking: registrationData?.parkovaniNe ?? 0,
  });

  const [foodInformation, setFoodInformation] = useState({
    thursdayBreakfast: registrationData?.snidaneCt ?? 0,
    thursdayLunch: registrationData?.obedCt ?? 0,
    thursdayDinner: registrationData?.vecereTeplaCt ?? 0,
    thursdayLunchBagette: registrationData?.obedBagetaCt ?? 0,
    thursdayDinnerBagette: registrationData?.vecereBagetaCt ?? 0,
    fridayBreakfast: registrationData?.snidanePa ?? 0,
    fridayLunch: registrationData?.obedPa ?? 0,
    fridayDinner: registrationData?.vecereTeplaPa ?? 0,
    fridayLunchBagette: registrationData?.obedBagetaPa ?? 0,
    fridayDinnerBagette: registrationData?.vecereBagetaPa ?? 0,
    saturdayBreakfast: registrationData?.snidaneSo ?? 0,
    saturdayLunch: registrationData?.obedSo ?? 0,
    saturdayDinner: registrationData?.vecereTeplaSo ?? 0,
    saturdayLunchBagette: registrationData?.obedBagetaSo ?? 0,
    saturdayDinnerBagette: registrationData?.vecereBagetaSo ?? 0,
    sundayBreakfast: registrationData?.snidaneNe ?? 0,
    sundayLunch: registrationData?.obedNe ?? 0,
    sundayLunchBagette: registrationData?.obedBagetaNe ?? 0,
  });

  const [isChecking, setIsChecking] = useState(false);

  const backToForm = () => {
    setIsChecking(false);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const checkRegexValidity = (): boolean => {
    let returnValue = true;
    if (!EmailRegex.test(basicInformation.email)) {
      setErrorMessage("Email není ve správném formátu.");
      returnValue = false;
    }

    if (!PhoneRegex.test(basicInformation.telefon)) {
      setErrorMessage("Telefon není ve správném formátu. (+420123456789)");
      returnValue = false;
    }

    if (!ZipRegex.test(basicInformation.zip)) {
      setErrorMessage("PSČ není ve správném formátu. (5 čísel)");
      returnValue = false;
    }

    accomodationInformation.persons.forEach((person: any) => {
      if (!ZipRegex.test(person.zip)) {
        setErrorMessage("PSČ není ve správném formátu. (5 čísel)");
        returnValue = false;
      }
    });

    return returnValue;
  };

  const [generatePDF, setGeneratePDF] = useState(false);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // quick fix, I want to go home
    if (!edit && signedIn && !withoutVS) {
      if (customVariableSymbol < 90000) {
        setError(true);
        setErrorMessage("Variabilní symbol musí být >= 90000.");
        setCustomVariableSymbolError(true);
        return;
      } else {
        setCustomVariableSymbolError(false);
      }
    }

    setSubmited(true);

    var check = checkAccomodation();
    if (!check) {
      scrollToError(true);
      setError(true);
      return;
    }

    var regexValidity = checkRegexValidity();
    if ((e.target as any).checkValidity() && regexValidity) {
      if (edit) {
        update();
      } else {
        setIsChecking(true);
        window.scrollTo({
          top: 0,
        });
      }
    } else {
      scrollToError(true);
    }

    if (!regexValidity) {
      setError(true);
    }
    console.log("submitted");
  };

  const scrollToError = (smooth: boolean) => {
    let scrollPosition = 0;
    const behavior: any = smooth ? "smooth" : "instant";
    try {
      scrollPosition =
        document
          .getElementsByTagName("form")[0]
          .querySelectorAll(":invalid")[0]
          .getBoundingClientRect().top +
        window.scrollY -
        100;
    } catch { }
    if (scrollPosition < 0) {
      scrollPosition = 0;
    }
    window.scrollTo({
      top: scrollPosition,
      behavior: behavior,
    });
  };

  const [submited, setSubmited] = useState<boolean>(false);
  const validateRequired = (content: string): boolean => {
    if ((content === "" || content === undefined) && submited) {
      return true;
    }
    return false;
  };

  const validateEmail = (email: string): boolean => {
    if (!EmailRegex.test(email) && submited) {
      return true;
    }
    return false;
  };

  const validatePhone = (phone: string): boolean => {
    if (!PhoneRegex.test(phone) && submited) {
      return true;
    }
    return false;
  };

  const validateZipCode = (zip: string): boolean => {
    if (!ZipRegex.test(zip) && submited) {
      return true;
    }
    return false;
  };

  const maxAccomodatedPersons = (): number => {
    return Math.max(
      accomodationInformation.thursday,
      accomodationInformation.friday,
      accomodationInformation.saturday,
      accomodationInformation.sunday
    );
  };

  const checkMaxPersonsTwoDays = (): boolean => {
    var max = maxAccomodatedPersons();

    let list: Array<number> = [
      Number(accomodationInformation.thursday),
      Number(accomodationInformation.friday),
      Number(accomodationInformation.saturday),
      Number(accomodationInformation.sunday),
    ];

    let returnValue = false;
    for (let i = 0; i < list.length - 1; i++) {
      if (list[i] === max && list[i + 1] === max) {
        returnValue = true;
      }
    }
    return returnValue;
  };

  const checkAccomodation = (): boolean => {
    let returnValue = checkAccomodationLimitations();
    if (!returnValue) {
      return returnValue;
    }

    returnValue = checkAccomodationPersonsNights();

    return returnValue;
  };

  const checkAccomodationPersonsNights = (): boolean => {
    let accomodationCapacity =
      Number(accomodationInformation.thursday) +
      Number(accomodationInformation.friday) +
      Number(accomodationInformation.saturday) +
      Number(accomodationInformation.sunday);

    let personsNights = 0;
    accomodationInformation.persons.forEach((person: any) => {
      personsNights += Number(person.nights);
    });

    if (accomodationCapacity !== personsNights) {
      setErrorMessage(
        "Celkový počet nocí ubytovaných osob se neshoduje s počtem nocí v ubytování."
      );
      return false;
    }
    return true;
  };

  const checkAccomodationLimitations = (): boolean => {
    if (
      parseInt(accomodationInformation.thursday as any) === 0 &&
      parseInt(accomodationInformation.friday as any) === 0 &&
      parseInt(accomodationInformation.saturday as any) === 0 &&
      parseInt(accomodationInformation.sunday as any) === 0
    ) {
      return true;
    }

    var limitationString = (
      accomodations[accomodationInformation.accomodation] as any
    )?.omezeni;
    var limitations = limitationString.split(";");
    var returnValue = true;
    limitations.forEach((limitation: string) => {
      switch (limitation) {
        case "0":
          if (!checkMaxPersonsTwoDays()) {
            setErrorMessage("Ubytování musí být minimálně na dva dny.");
            returnValue = false;
          }
          break;
        case "1":
          checkGroupSize(
            2,
            4,
            "Ubytování je možné pouze pro dvojice, maximální počet osob je 4."
          );
          break;
        case "2":
          checkGroupSize(
            3,
            6,
            "Ubytování je možné pouze pro trojice, maximální počet osob je 6."
          );
          break;
      }

      function checkGroupSize(
        groupSize: number,
        maxSize: number,
        message: string
      ) {
        if (
          accomodationInformation.thursday % groupSize !== 0 ||
          accomodationInformation.friday % groupSize !== 0 ||
          accomodationInformation.saturday % groupSize !== 0 ||
          accomodationInformation.sunday % groupSize !== 0 ||
          maxAccomodatedPersons() > maxSize
        ) {
          setErrorMessage(message);
          returnValue = false;
        }
      }
    });

    return returnValue;
  };

  const updateDatas = () => {
    console.log(registrationId);
    setIsPending(true);

    fetch("/api/Registration/data", {
      method: "GET",
      headers: {
        Authorization: edit || signedIn
          ? `Bearer ${localStorage.getItem("userToken")}`
          : "",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 404) {
            window.location.href = "/ukonceno";
            return;
          }
          throw Error("Couldn not fetch operations for that resource!");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        var today = new Date();

        setPresentations(data?.presentations);
        setListeners(() => {
          var tmp = [];
          for (var i = 0; i < data?.presentations.length; i++) {
            tmp.push(0);
          }
          return tmp;
        });
        setAccomodations(data?.accomodations ?? []);
        if (registrationData?.ubytovaniId) {
          setAccomodationInformation({
            ...accomodationInformation,
            accomodation:
              data?.accomodations.findIndex(
                (item: any) => item.id === registrationData?.ubytovaniId
              ) ?? 0,
          });
        }
        if (registrationData && registrationData.datumObjednani) {
          today = new Date(registrationData.datumObjednani);
        }
        const konecPredterminu = data?.terms[0]?.konecPredterminu;
        const newDate =
          (konecPredterminu ? new Date(konecPredterminu) : null) ?? today;
        setDate(newDate);
        setExchangeRate(data?.exchangeRate?.konverze ?? 25);

        setPrices(() => {
          var tmp = data?.costs[0];
          if (tmp === undefined || tmp === null) {
            return {
              bagette: 0,
              parking: 0,
              wholeDay: 0,
              wholeMultiday: 0,
              wholeAfter: 0,
              wholeBefore: 0,
              reducedDay: 0,
              reducedMultiday: 0,
              reducedAfter: 0,
              reducedBefore: 0,
              breakfast: 0,
              lunch: 0,
              dinner: 0,
            };
          }
          return {
            bagette: tmp.bageta,
            parking: tmp.parkovani,
            wholeDay: tmp.plnyDen,
            wholeMultiday: today > newDate ? tmp.plnyPo : tmp.plnyPred,
            wholeAfter: tmp.plnyPo,
            wholeBefore: tmp.plnyPred,
            reducedDay: tmp.snizenyDen,
            reducedMultiday: today > newDate ? tmp.snizenyPo : tmp.snizenyPred,
            reducedAfter: tmp.snizenyPo,
            reducedBefore: tmp.snizenyPred,
            breakfast: tmp.snidane,
            lunch: tmp.obed,
            dinner: tmp.vecere,
          };
        });
        console.log(prices);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .then(() => {
        setIsPending(false);
      });
  };

  useEffect(() => {
    updateDatas();
  }, []);

  const setRegisterDataDTO = (token: string) => {
    // define variable symbol from the form
    const formVariableSymbol =
      formInformation.variableSymbol === null ||
      isNaN(Number(formInformation.variableSymbol))
        ? null
        : Number(formInformation.variableSymbol);

    // define final variable symbol based on if user is authorized or not
    let variableSymbol = signedIn
      ? customVariableSymbol === 0
        ? formVariableSymbol
        : customVariableSymbol
      : formVariableSymbol;

    if (withoutVS) {
      variableSymbol = -1;
    }

    // pritomen && smazal id usera?
    console.log(registrationId);
    const data = {
      token: token,
      id: registrationId ?? 0,
      pritomen: formInformation.present,
      varSymbol: variableSymbol,
      jmeno: basicInformation.firstName,
      prijmeni: basicInformation.lastName,
      ulice: basicInformation.street,
      mesto: basicInformation.city,
      psc: basicInformation.zip.toString().replace(/\s/g, ""),
      telefon: basicInformation.telefon,
      email: basicInformation.email,
      poplatekPlny: conferencePriceCount.multiday,
      poplatekSnizeny: conferencePriceCount.multidayReduced,
      poplatek1DenPlnyCt: conferencePriceCount.thursday,
      poplatek1DenSnizenyCt: conferencePriceCount.thursdayReduced,
      poplatek1DenPlnyPa: conferencePriceCount.friday,
      poplatek1DenSnizenyPa: conferencePriceCount.fridayReduced,
      poplatek1DenPlnySo: conferencePriceCount.saturday,
      poplatek1DenSnizenySo: conferencePriceCount.saturdayReduced,
      poplatek1DenPlnyNe: conferencePriceCount.sunday,
      poplatek1DenSnizenyNe: conferencePriceCount.sundayReduced,
      deti15: childrenUnder15.length,
      detskaKonference: childrenUnder15.filter((x) => x.conference).length,
      celkemPoplatky: sumConferencePrice,
      ubytovaniId:
        accomodationInformation.accomodation < 0
          ? 0
          : (accomodations[accomodationInformation.accomodation] as any)?.id ??
            0,
      ubytovaniSt: accomodationInformation.thursday,
      ubytovaniCt: accomodationInformation.friday,
      ubytovaniPa: accomodationInformation.saturday,
      ubytovaniSo: accomodationInformation.sunday,
      celkemUbytovani: isNaN(accomodationSum.accomodation)
        ? 0
        : accomodationSum.accomodation,
      parkovaniCt: accomodationInformation.thursdayParking,
      parkovaniPa: accomodationInformation.fridayParking,
      parkovaniSo: accomodationInformation.saturdayParking,
      parkovaniNe: accomodationInformation.sundayParking,
      celkemParkovani: accomodationSum.parking,
      poplatekMB: accomodationSum.cityTax,
      snidaneCt: foodInformation.thursdayBreakfast,
      obedCt: foodInformation.thursdayLunch,
      obedBagetaCt: foodInformation.thursdayLunchBagette,
      vecereBagetaCt: foodInformation.thursdayDinnerBagette,
      vecereTeplaCt: foodInformation.thursdayDinner,
      snidanePa: foodInformation.fridayBreakfast,
      obedPa: foodInformation.fridayLunch,
      obedBagetaPa: foodInformation.fridayLunchBagette,
      vecereBagetaPa: foodInformation.fridayDinnerBagette,
      vecereTeplaPa: foodInformation.fridayDinner,
      snidaneSo: foodInformation.saturdayBreakfast,
      obedSo: foodInformation.saturdayLunch,
      obedBagetaSo: foodInformation.saturdayLunchBagette,
      vecereBagetaSo: foodInformation.saturdayDinnerBagette,
      vecereTeplaSo: foodInformation.saturdayDinner,
      snidaneNe: foodInformation.sundayBreakfast,
      obedNe: foodInformation.sundayLunch,
      obedBagetaNe: foodInformation.sundayLunchBagette,
      celkemStravovani: sumFoodPrice,
      dobrovolnyPrispevek: contribution,
      dobrovolnyPrispevekDeti: contributionChildrenUnder15,
      cenaCelkem: sumTotalPrice,
      smazano: formInformation.deleted,
      smazalId: 0,
      zeme: basicInformation.state,
      neposilatUpozorneni: false,
      ubytovaniOsoby: accomodationInformation.persons.map(
        (x: {
          firstName: string;
          lastName: string;
          street: string;
          city: string;
          zip: string;
          eighteen: boolean;
          nights: number;
        }) => {
          return {
            jmeno: x.firstName,
            prijmeni: x.lastName,
            ulice: x.street,
            mesto: x.city,
            psc: x.zip,
            platiPoplatek: x.eighteen,
            pocetNoci: x.nights,
          };
        }
      ),
      deti: childrenUnder15.map(
        (x: { name: string; age: number | undefined; conference: boolean }) => {
          return {
            jmeno: x.name,
            vek: x.age,
            dk: x.conference,
          };
        }
      ),
      prednaskyPocet: !edit
        ? listeners.map((x: number, index: number) => {
            return {
              prednaskaId: (presentations[index] as any)?.id,
              pocet: x,
            };
          })
        : [],
    };

    return data;
  };

  const handleRegister = () => {
    setIsPending(true);
    setError(false);

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token: any) => {
          register(token as string);
        });
    });
  };

  const register = (token: string) => {
    const data = setRegisterDataDTO(token);

    console.log(data);
    var fetchError = false;
    fetch("/api/Registration/create", {
      method: "POST",
      headers: {
        Authorization: signedIn
          ? `Bearer ${localStorage.getItem("userToken")}`
          : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res);
        if (!res.ok) {
          if (res.status === 400) {
            setError(true);
            fetchError = true;
            return res.json();
          } else {
            throw Error("Could not fetch the data for that resource!");
          }
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setIsPending(false);
        setIsChecking(false);
        if (!fetchError) {
          setId(data);
          setIsSended(true);
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setErrorMessage(data.message);
          console.log(data.message);
        }
      })
      .catch((err) => {
        setIsPending(false);
        console.log(err);
        console.log(err.message);
      });
  };

  const registerWithPayment = () => {
    setIsPending(true);
    const registrationData = setRegisterDataDTO("");
    registrationData.pritomen = true;

    const paymentData = {
      varSymbol: 0,
      castka: payment.castka,
      mena: payment.mena,
      typ: payment.typ,
    };

    const data = { registration: registrationData, payment: paymentData };

    var fetchError = false;
    fetch("/api/Registration/createOnSite", {
      method: "POST",
      headers: {
        Authorization: signedIn
          ? `Bearer ${localStorage.getItem("userToken")}`
          : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 400) {
            setError(true);
            fetchError = true;
            return res.json();
          } else {
            throw Error("Could not fetch the data for that resource!");
          }
        }
        return res.json();
      })
      .then((data) => {
        setIsPending(false);
        setIsChecking(false);
        if (!fetchError) {
          setId(data.id);
          setIsSended(true);
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setErrorMessage(data.message);
          console.log(data.message);
        }
      })
      .catch((err) => {
        setIsPending(false);
        console.log(err);
        console.log(err.message);
        enqueueSnackbar("Chyba při vytváření přihlášky", { variant: "error" });
      });
  };

  const update = (isPdf: boolean = false) => {
    setIsPending(true);
    setError(false);

    const registrationData = setRegisterDataDTO("");
    let data: any = { registration: registrationData };
    if (payment.castka !== 0) {
      data.payment = {
        varSymbol: formInformation.variableSymbol,
        castka: payment.castka,
        mena: payment.mena,
        typ: payment.typ,
      };
    }

    console.log(data);
    var fetchError = false;
    fetch("/api/Registration/update?generatePDF=" + (generatePDF || isPdf), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res);
        if (!res.ok) {
          if (res.status === 409) {
            setError(true);
            fetchError = true;
            return res.json();
          } else {
            throw Error("Could not fetch the data for that resource!");
          }
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setIsPending(false);
        if (!fetchError) {
          //navigate("/admin/prihlasky");
          enqueueSnackbar("Úspěšně uloženo", { variant: "success" });
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setErrorMessage(data.message);
          console.log(data.message);
        }
      })
      .catch((err) => {
        setIsPending(false);
        console.log(err);
        console.log(err.message);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInformation({
      ...formInformation,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleRevert = () => {
    navigate("/admin/prihlasky");
  };

  const handleRecalculate = () => {
    //TODO
  };

  const [openWarningDialogD, setOpenWarningDialogD] = useState(false);
  const [openWarningDialogR, setOpenWarningDialogR] = useState(false);

  const [warningDialogMessage, setWarningDialogMessage] = useState("");
  const [warningDialogTitle, setWarningDialogTitle] = useState("");

  const handleDelete = () => {
    let isError = false;
    fetch(
      "/api/Registration/delete?id=" + registrationId ??
        "0" + "&userId=" + localStorage.getItem("userID") ??
        "0",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          isError = true;
          return res.json();
        }
        return res.json();
      })
      .then((d) => {
        if (!isError) {
          navigate("/admin/prihlasky");
        } else {
          setErrorMessage(d.message);
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
      });
  };

  const handleRestore = () => {
    let isError = false;
    fetch("/api/Registration/restore?id=" + registrationId ?? "0", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          isError = true;
        }
        return res.json();
      })
      .then((d) => {
        if (!isError) {
          navigate("/admin/prihlasky");
        } else {
          setErrorMessage(d.message);
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
      });
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <script src="https://www.google.com/recaptcha/api.js?render=6Lfjm7UpAAAAAKFQbwfcdpcnl_EMfIQoigDPDScR"></script>

      <Content>
        {isPending && <div style={{ gridColumn: "1 / 3" }}>Načítání...</div>}
        {!isPending && !isChecking && !isSended && (
          <>
            <ErrorDialog
              open={error}
              setOpen={setError}
              errorMessage={errorMessage}
            />
            {edit && (
              <WarningDialog
                open={openWarningDialogD}
                setOpen={setOpenWarningDialogD}
                Message={warningDialogMessage}
                Header={warningDialogTitle}
                Action={handleDelete}
              />
            )}

            {edit && (
              <WarningDialog
                open={openWarningDialogR}
                setOpen={setOpenWarningDialogR}
                Message={warningDialogMessage}
                Header={warningDialogTitle}
                Action={handleRestore}
              />
            )}

            {edit && (
              <div className="form-edit--buttons-container">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="present"
                      value={formInformation.present}
                      checked={formInformation.present}
                      onChange={handleChange}
                    />
                  }
                  label="Přítomen"
                />
                <Button
                  type="button"
                  onClick={handleRecalculate}
                  variant="contained"
                >
                  Přepočítat
                </Button>
                {/*<Button
                  type="submit"
                  variant="contained"
                  onClick={() => setGeneratePDF(false)} // Set generatePDF to false when "Uložit" button is clicked
                >
                  Uložit
                </Button>*/}
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => {
                    update(false);
                  }}
                >
                  Uložit změny
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => setGeneratePDF(true)} // Set generatePDF to true when "Uložit a vygenerovat PDF" button is clicked
                >
                  Uložit a vygenerovat PDF
                </Button>
                <Button
                  type="button"
                  onClick={handleRevert}
                  variant="contained"
                >
                  Zrušit
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<HomeIcon />}
                  onClick={() => {
                    navigate("/admin");
                  }}
                >
                  Domů
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    window.open(
                      linkUrl +
                        "?guid=" +
                        btoa(localStorage.getItem("userToken") as string),
                      "_blank"
                    );
                    // displayPDFInNewWindowWithAuthorization(
                    //   linkUrl,
                    //   headers
                    // ).catch(() => {
                    //   enqueueSnackbar("Nepodařilo se načíst soubor.", {
                    //     variant: "error",
                    //   });
                    // });
                  }}
                  variant="outlined"
                >
                  PDF
                </Button>
                {!registrationData?.smazano && (
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setWarningDialogMessage(
                        "Opravdu chcete smazat přihlášku?"
                      );
                      setWarningDialogTitle("Smazat přihlášku");
                      setOpenWarningDialogD(true);
                    }}
                  >
                    Smazat
                  </Button>
                )}
                {registrationData?.smazano && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setWarningDialogMessage(
                        "Opravdu chcete obnovit přihlášku?"
                      );
                      setWarningDialogTitle("Obnovit přihlášku");
                      setOpenWarningDialogR(true);
                    }}
                  >
                    Obnovit
                  </Button>
                )}
              </div>
            )}

            {edit && (
              <>
                <div className="row">
                  <div id="variableSymbol">
                    <h2>Přihláška č. </h2>
                    <TextField
                      type="text"
                      name="variableSymbol"
                      label="Variabilní symbol"
                      required
                      value={formInformation.variableSymbol}
                      onChange={handleChange}
                      size="small"
                      error={validateRequired(formInformation.variableSymbol)}
                      disabled={true}
                    />
                  </div>

                  <BasicInformation
                    validatePhone={validatePhone}
                    validateEmail={validateEmail}
                    validateZip={validateZipCode}
                    validateRequired={validateRequired}
                    basicInformation={basicInformation}
                    setBasicInformation={setBasicInformation}
                  />

                  <div id="deleteCheckbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="deleted"
                          value={formInformation.deleted}
                          checked={formInformation.deleted}
                          disabled
                        />
                      }
                      label="Smazáno"
                    />
                  </div>
                </div>
                <div className="column">
                  <ConferencePrice
                    sum={sumConferencePrice}
                    setSum={setSumConferencePrice}
                    prices={prices}
                    form={conferencePriceCount}
                    setForm={setConferencePriceCount}
                  />

                  <Food
                    sum={sumFoodPrice}
                    setSum={setSumFoodPrice}
                    prices={prices}
                    form={foodInformation}
                    setForm={setFoodInformation}
                  />

                  <TotalPrice
                    editMode={edit}
                    sum={sumTotalPrice}
                    setSum={setSumTotalPrice}
                    exchangeRate={exchangeRate}
                    contribution={contribution}
                    setContribution={setContribution}
                    conference={sumConferencePrice}
                    accomodation={accomodationSum}
                    food={sumFoodPrice}
                    childContribution={contributionChildrenUnder15}
                    state={basicInformation.state}
                    toPay={registrationData?.zbyva ?? 0}
                    payedAmount={registrationData?.zaplacenaCastka ?? 0}
                    variableSymbol={formInformation.variableSymbol}
                    update={update}
                    setPayment={setPayment}
                  />
                </div>
                <div className="column">
                  <Accomodation
                    validateRequired={validateRequired}
                    validateZip={validateZipCode}
                    sum={accomodationSum}
                    setSum={setAccomodationSum}
                    accomodations={accomodations}
                    prices={prices}
                    form={accomodationInformation}
                    setForm={setAccomodationInformation}
                  />
                  <ChildrenUnder15
                    validateRequired={validateRequired}
                    contribution={contributionChildrenUnder15}
                    setContribution={setContributionChildrenUnder15}
                    form={childrenUnder15}
                    setForm={setChildrenUnder15}
                  />
                </div>
              </>
            )}

            {!edit && (
              <>
                {signedIn && !withoutVS && (
                  <CustomVariableSymbol
                    error={customVariableSymbolError}
                    varSymbol={customVariableSymbol}
                    setVarSymbol={setCustomVariableSymbol}
                  />
                )}
                <BasicInformation
                  validatePhone={validatePhone}
                  validateEmail={validateEmail}
                  validateZip={validateZipCode}
                  validateRequired={validateRequired}
                  basicInformation={basicInformation}
                  setBasicInformation={setBasicInformation}
                />

                <ConferencePrice
                  sum={sumConferencePrice}
                  setSum={setSumConferencePrice}
                  prices={prices}
                  form={conferencePriceCount}
                  setForm={setConferencePriceCount}
                />

                <ChildrenUnder15
                  validateRequired={validateRequired}
                  contribution={contributionChildrenUnder15}
                  setContribution={setContributionChildrenUnder15}
                  form={childrenUnder15}
                  setForm={setChildrenUnder15}
                />

                <Accomodation
                  validateRequired={validateRequired}
                  validateZip={validateZipCode}
                  sum={accomodationSum}
                  setSum={setAccomodationSum}
                  accomodations={accomodations}
                  prices={prices}
                  form={accomodationInformation}
                  setForm={setAccomodationInformation}
                />

                <Food
                  sum={sumFoodPrice}
                  setSum={setSumFoodPrice}
                  prices={prices}
                  form={foodInformation}
                  setForm={setFoodInformation}
                />

                <TotalPrice
                  editMode={edit || withoutVS}
                  sum={sumTotalPrice}
                  setSum={setSumTotalPrice}
                  exchangeRate={exchangeRate}
                  contribution={contribution}
                  setContribution={setContribution}
                  conference={sumConferencePrice}
                  accomodation={accomodationSum}
                  food={sumFoodPrice}
                  childContribution={contributionChildrenUnder15}
                  state={basicInformation.state}
                  toPay={undefined}
                  payedAmount={undefined}
                  variableSymbol={undefined}
                  onSite={withoutVS}
                  setPayment={setPayment}
                />

                <Presentation
                  presentations={presentations}
                  form={listeners}
                  setForm={setListeners}
                />
              </>
            )}
            {!edit && !withoutVS && (
              <Button type="submit" variant="contained">
                Pokračovat
              </Button>
            )}
            {!edit && withoutVS && (
              <Button
                type="button"
                onClick={registerWithPayment}
                variant="contained"
              >
                Odeslat
              </Button>
            )}
          </>
        )}
        {!isPending && isChecking && !isSended && (
          <>
            <CheckTable
              basicInformation={basicInformation}
              conferencePriceCount={conferencePriceCount}
              sumConferencePrice={sumConferencePrice}
              childrenUnder15={childrenUnder15}
              accomodationInformation={accomodationInformation}
              accomodations={accomodations}
              foodInformation={foodInformation}
              sumTotalPrice={sumTotalPrice}
              contributionChildrenUnder15={contributionChildrenUnder15}
              contribution={contribution}
              exchangeRate={exchangeRate}
            />

            <div className="form--buttons-container">
              <Button type="button" onClick={backToForm} variant="contained">
                Zpět
              </Button>
              <Button
                type="button"
                onClick={handleRegister}
                variant="contained"
              >
                Odeslat
              </Button>
            </div>
          </>
        )}

        {!isPending && !isChecking && isSended && !withoutVS && (
          <RegistrationSended id={id} exchangeRate={exchangeRate} />
        )}
        {!isPending && !isChecking && isSended && withoutVS && (
          <>
            <h2 className="subform-header">Přihláška odeslána</h2>
            <div style={{ padding: "10px" }}>
              <a href={"/admin/prihlasky/" + id}>Zobrazit přihlášku</a>
            </div>
            <Button type="button" onClick={reloadPage} variant="contained">
              Založit další přihlášku
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={<HomeIcon />}
              style={{
                marginLeft: "20px",
                minWidth: "200px",
                whiteSpace: "nowrap",
              }}
              onClick={goHome}
            >
              Domů
            </Button>
          </>
        )}
      </Content>
    </form>
  );
};

export default FormContainer;
