import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormContainer from "../../form/formContainer";
import "./registrationEdit.css";

const RegistrationEdit = () => {
  const { id } = useParams();
  const [isPending, setIsPending] = useState(true);
  const [registrationData, setRegistrationData] = useState<any>(null);

  useEffect(() => {
    setIsPending(true);
    fetch("/api/Registration/" + id, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          setIsPending(false);
          throw Error("Could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data) => {
        for (let i = 0; i < data.ubytovaniOsoby.length; i++) {
          data.ubytovaniOsoby[i].firstName = data.ubytovaniOsoby[i].jmeno;
          data.ubytovaniOsoby[i].lastName = data.ubytovaniOsoby[i].prijmeni;
          data.ubytovaniOsoby[i].street = data.ubytovaniOsoby[i].ulice;
          data.ubytovaniOsoby[i].city = data.ubytovaniOsoby[i].mesto;
          data.ubytovaniOsoby[i].zip = data.ubytovaniOsoby[i].psc;
          data.ubytovaniOsoby[i].eighteen =
            data.ubytovaniOsoby[i].platiPoplatek;
          data.ubytovaniOsoby[i].nights = data.ubytovaniOsoby[i].pocetNoci;
        }

        for (let i = 0; i < data.deti.length; i++) {
          data.deti[i].name = data.deti[i].jmeno;
          data.deti[i].age = data.deti[i].vek;
          data.deti[i].conference = data.deti[i].dk;
        }

        setRegistrationData(data);
        setIsPending(false);
      })
      .catch((err) => {
        setIsPending(false);
        console.log(err.message);
      });
  }, []);

  return (
    <div id="edit-registration">
      {(isPending || !id) && <div>Načítání...</div>}
      {!isPending && id && (
        <FormContainer
          registrationId={Number(id)}
          edit={true}
          registrationData={registrationData}
        />
      )}
    </div>
  );
};

export default RegistrationEdit;
