import ReactDOM from "react-dom";
import LabelsContainer from "./labelsContainer";
import qrcode from "./qrcode.svg";

const openLabelPage = async (variableSymbols: Number[]) => {
  const newWindow = window.open("", "_blank");
  if (newWindow) {
    // Základní HTML struktura
    newWindow.document.write(`
      <html>
        <head>
          <title>Charis štítky</title>
          <style>
            @media print {
              @page {
                size: A4 landscape;
              }
              .conference-label-container > :nth-child(2n + 1) {
                padding-right: 1.5rem; 
                border-right: 1px dashed black; 
              }
              .conference-label-container > :nth-child(2n) {
                  padding-left: 1.5rem;
              }
              .label-div {
                margin-right: 0 !important;
                margin-left: 0 !important;
                height: calc(100vh - 1rem); !important;
                max-height: calc(100vh - 1rem); !important;
              }
            }
            .label-div {
              margin-right: 45px;
              margin-left: 45px;
              height: 690px;
            }
            .conference-label-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: left; //"left" - to make last odd child appear on the left in print
            }
            .conference-label {
              width: fit-content;
            }
            .conference-label * {
              font-family: Arial,Verdana,Tahoma, sans-serif;
              font-size: 12px;
              color: #000000;
            }
            .label-table {
              border-collapse: collapse;
            }
            .conference-label h2, .conference-label h2 * {
              font-size: 16px;
              font-weight: bold;
              margin-top: 12px;
              margin-bottom: 8px;
            }
            .conference-label h2 {
              padding-left: 0.3rem;
            }
            .label-border {
              border: 1px solid Black;
              padding: 2px;
              width: 450px;
            }
            .label-table td {
              border: solid 1px black;
            }
          </style>
        </head>
        <body>
          <div id="root"></div>
        </body>
      </html>
    `);

    ReactDOM.render(
      <LabelsContainer variableSymbols={variableSymbols} qrcode={qrcode} />,
      newWindow.document.getElementById("root")
    );
  }
};

export default openLabelPage;
