import Header from "../common/header";


const AdministrationHeader = () => {
  return (
    <Header>
      <a href="/admin" style={{ textDecoration: 'none', color: 'initial', display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 10}}>
        <img src="/Charis_512.png" alt="Charis Logo" style={{ width: '100px', height: 'auto', marginRight: '20px'}} />
        <div style={{display: "flex", flexDirection: "column"}}>
          <h1 style={{ margin: 0, textAlign: "left" }}> Konference Charis 2024 - administrace </h1>
          <h3 style={{ marginTop: 10, textAlign: "left" }}>Brno výstaviště 10. - 14. 7. 2024</h3>
        </div>
      </a>
    </Header>
  );
};

export default AdministrationHeader;
